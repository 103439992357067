import { Component, Vue, Prop, Emit, Inject, Watch } from 'vue-property-decorator';
import { CustomField, DateTimeType } from '../../common/types/AllTypesExports';
import Datepicker from 'vuejs-datepicker';
import TimePicker from '../time-picker/TimePicker.vue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)
dayjs.extend(customParseFormat)

@Component({
  components: {
    Datepicker,
    //VueTimepicker,
    TimePicker,
    FieldValidationErrors
  },
})
export default class CustomFieldDateTimeEditor extends Vue {
    //External
    @Prop({required: true})
    fieldConfig!: CustomField;

    @Prop({required: true})
    value?: string;

    @Prop({ required: true })
    id!: string;

    @Prop({ required: false, default: ''})
    validatorScope!: string;

    @Prop({ required: false, default: false})
    readOnly!: boolean;

    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;

    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    selectedDate? : string;
    selectedTime? : string;

    created() {
        this.setSelectedDateAndTime();
    }

    @Watch('value')
    // If the value prop changes outside of this component (e.g. auto-population of an attendee custom field value) then the update needs to be passed to the date/time pickers, which are bound to selectedDate/selectedTime
    setSelectedDateAndTime() {
        if (!this.value)
            return;

        switch (this.fieldConfig.dateTimeType) {
            case DateTimeType.TimeOnly:
                this.selectedTime = this.value;
                break;
            case DateTimeType.DateOnly:
                this.selectedDate = this.value;
                break;
            case DateTimeType.DateTime:
                var input = dayjs(this.value, "L LT");
                this.selectedDate = input.format('L');
                this.selectedTime = input.format('LT');
                break;
        }
    }

    @Emit('input')
    emitInput() {

        switch(this.fieldConfig.dateTimeType) {
            case DateTimeType.TimeOnly:
                if (!this.selectedTime)
                    return "";

                var timeMoment = dayjs(this.selectedTime, "HH:mm");
                return timeMoment.format("LT");
            case DateTimeType.DateOnly: 
                if (!this.selectedDate)
                    return "";

                var dateMoment = dayjs(this.selectedDate);
                return dateMoment.format('L');
            case DateTimeType.DateTime:
                if (!this.selectedDate)
                    return "";
                var dateMoment = dayjs(this.selectedDate);
                var timeMoment = dayjs(this.selectedTime, "HH:mm");

                var dateTimeMoment = dateMoment.set(
                    'hour',timeMoment.get('hour')).set(
                    'minute', timeMoment.get('minute')
                    );
                return dateTimeMoment.format("L LT");
            }
    }

    get disabledDates() : object {
        var config = {
            to: this.fieldConfig.minDate ? new Date(this.fieldConfig.minDate) : null,
            from: this.fieldConfig.maxDate ? new Date(this.fieldConfig.maxDate) : null
        };

        return config;
    }

    get dateOnly() : boolean {
        return !this.fieldConfig.dateTimeType;
    }
    get timeOnly() : boolean {
        return this.fieldConfig.dateTimeType == DateTimeType.TimeOnly;
    }

    get rules() {
        return {
            required: this.required
        }
    }

    get required() {
        return (this.isRequired == null && this.fieldConfig.requiredFrontend && !this.readOnly) || (this.isRequired != null && this.isRequired);
    }

    get showRestrictionsMessage() {
        return this.readOnly && !this.hideDisabledMessage;
    }
}