import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import { ApiErrorResponse } from '../types/AllTypesExports';
import NoviLogging from '../services/NoviLogging';
import { INoviNsGlobals } from '../NoviNsGlobals';

declare let noviNs : INoviNsGlobals;

/**
 * @description Handles base level parsing for Novi API Responses
 */
class BaseNoviApiRepo {

    protected getNoviApiErrorHandler<T = any>() {
        return function (axiosError: AxiosError): Promise<T> {
            const axiosResponse = <AxiosResponse<ApiErrorResponse>>axiosError.response;
            NoviLogging.error(axiosResponse.data.message, axiosResponse.data);
            throw axiosResponse;
        }
    }

    protected getNoviApiDataHandler<T = any>() {
        return function (axiosResponse: AxiosResponse<T>) {
            return axiosResponse.data;
        }
    }

    protected post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return axios.post(url, data, this.addXsrfToken(config));
    }

    protected put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return axios.put(url, data, this.addXsrfToken(config));
    }

    protected delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return axios.delete(url, this.addXsrfToken(config));
    }

    protected get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return axios.get(url, config); //Gets have no side effects, therefore are not subject to XSRF validation
    }

    private addXsrfToken(config?: AxiosRequestConfig) : AxiosRequestConfig{
        if (!noviNs.xsrfToken)
            throw "Invalid attempt to call a mutating function with no XSRF token.";
            
        var result = config || {};
        result.headers = result.headers || {};
        result.headers["X-Xsrf-Token"] = noviNs.xsrfToken;

        return result;
    }

}

export default BaseNoviApiRepo