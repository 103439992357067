import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import { INoviNsRecaptchaGlobals } from '@/common/types/recaptcha/NoviNsRecaptchaGlobals';

declare let noviNs: INoviNsRecaptchaGlobals;

@Component({
    name: 'Recaptcha',
    components: {}
})
export default class RecaptchaInputClass extends Vue {

    token: string = '';

    mounted() {
        var self = this;
        grecaptcha.render(
            'recaptchaTarget', {
                'sitekey': noviNs.recaptchaSiteKey,
                'callback': (response: string) => self.token = response,
                'expired-callback': () => self.token = '',
                'error-callback': () => self.token = ''
            }
        );
    }

    reset() {
        grecaptcha.reset();
    }

    @Emit('update:recaptcha-token')
    @Watch('token')
    emitInput() {
        return this.token;
    }
}