
export interface QboApiErrorResponse {
    errors: QboApiError[];
}

export interface QboApiError {
    code: string,
    detail: string,
    infoLink: string,
    message: string,
    moreInfo: string,
    type: string
}

export function getQboErrorMessage(errorResponse?: QboApiErrorResponse) {
    return errorResponse && errorResponse.errors && errorResponse.errors.length > 0 ?
         errorResponse.errors[0].message :
        'Please retry; if the error persists please reach out.';
}

export function createQboApiErrorResponse(obj?: QboApiErrorResponse | any) {
    const result = <QboApiErrorResponse>{
        errors: []
    };

    if (obj) {
        Object.assign(result, obj);
    }

    return result;
}

export function createQboApiError(obj?: QboApiError | any) {
    const result = <QboApiError>{
        code: '',
        detail: '',
        infoLink: '',
        message: '',
        moreInfo: '',
        type: ''
    };

    if (obj) {
        Object.assign(result, obj);
    }

    return result;
}