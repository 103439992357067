export interface BasicSelectListItem {
    id: number;
    displayName: string;
    taxable: boolean;
    isDefault: boolean;
}

export function createBasicSelectListItem(instance?: BasicSelectListItem | any) {
    const obj = <BasicSelectListItem>{
        id: 0,
        displayName: '',
        taxable: true,
        isDefault: false,
    };
    if (instance) {
        Object.assign(obj, instance);
    }
    return obj;
}