export interface PhoneFormatOptions {
    allowInternational: boolean;
    allowExtensions: boolean;
    extensionPrefix: string;
    includeParens: boolean;
    delimiterText: string;
    maxCharacters: number;
}

export function createPhoneFormatOptions(options?: PhoneFormatOptions | any) {
    const obj = <PhoneFormatOptions>{
        allowExtensions: false,
        allowInternational: false,
        delimiterText: '-',
        extensionPrefix: 'x',
        includeParens: true,
        maxCharacters: 25
    };

    if (options) {
        Object.assign(obj, options);
    }
    return obj;
}