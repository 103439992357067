import axios, { AxiosResponse, AxiosError } from 'axios';
import NoviLogging from "@/common/services/NoviLogging";
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';

declare let noviNs: INoviNsPaymentGlobals;

class QuickbooksPaymentsRepo {
    getQuickbooksPaymentToken(card: any) {
        return axios.post<{ value: string }>(noviNs.quickbooksPaymentsUrl + 'tokens', card)
            .then((axiosResponse: AxiosResponse<{ value: string }>) => {
                return axiosResponse.data.value;
            })
            .catch((axiosError: AxiosError) => {
                throw NoviLogging.qboApiError(axiosError);
            });
    }
}

export const qbPaymentsRepo = new QuickbooksPaymentsRepo();