import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { TimeItem } from './TimeItem';
import ComboboxHighlightMixin from '../mixins/ComboboxHighlightMixin.vue';
import { mixins } from 'vue-class-component';

@Component({
})
export default class TimePicker extends mixins(ComboboxHighlightMixin) {

    @Prop({required: true})
    value?: string;
    @Prop({default: 30})
    minuteInterval : number = 30;
    @Prop({ required: true })
    id!: string;
    @Prop({required: false, default: false})
    readOnly!: boolean;

    showDropdown : boolean = false;
    inputTime: string = "";
    storedTime: TimeItem | null = null;
    validTimeRegex : RegExp = /^(?:[01]*\d:\d\d\s*[apAP]*[mM]*)|(?:[2][01234]:\d\d)/;

    mounted() {
        
    }

    highlightStoredTime() {
        var timeToMatch = this.storedTime ? this.storedTime.fullTime : "09:00";
        var matchingTime = this.times.filter(time => {
            if (time.fullTime == timeToMatch)
                return time;
        });

        if (matchingTime && matchingTime.length > 0) {
            this.setPointer(this.times.indexOf(matchingTime[0]));
        }
    }

    @Watch('value', { immediate: true })
    onValueChanged(val: string, oldVal: string) { 
        if (val)
            this.setTime(this.parseInput(val, null));
    }

    inputFocus() {
        if (this.readOnly)
            return; 

        this.showDropdown = true;
        Vue.nextTick().then(() => {
            this.highlightStoredTime();
        });
        
    }

    inputBlur() {
        this.showDropdown = false;
        if (!this.storedTime || this.inputTime != this.storedTime.displayTime) {
            this.setTime(this.parseInput(this.inputTime, this.storedTime, true));
        }
    }

    getTimeElementId(index : number) {
        return this.id + "_option" + index;
    }


    @Emit('input')
    emitInput() {
        return this.storedTime ? this.storedTime.fullTime : "";
    }

    /**
     * Parse a TimeItem (with 24 hour and 12 hour times) out of the given input
     * @param timeString The string to parse into a TimeItem
     * @param fallback The TimeItem value to return if the parse of timeString is unsuccessful
     * @param interpret Indicates whether we are interpreting the am/pm values of the input based on office hours
     */
    private parseInput(timeString: string, fallback: TimeItem | null, interpret? :boolean) : TimeItem | null {

        if (!timeString) {
            return null;
        }
        if (!this.validTimeRegex.test(timeString.trim())) {
            return fallback;
        }


        const colonIndex = timeString.indexOf(":");
        const minutesString = timeString.substring(colonIndex + 1, colonIndex + 3);
        const hoursString = timeString.substring(0, colonIndex);
        const minutes = parseInt(minutesString, 10);
        const hour = parseInt(hoursString, 10);

        const hasampm = timeString.length -1 > colonIndex + 2;

        let ampm = hasampm ? timeString.substring(colonIndex + 3).trim().toUpperCase() : "";

        let isAM = false;

        if (!hasampm) { //If we were passed a value without AM/PM, we need to determine it in order to build displayTime
            if (interpret && hour != 0) { //If this is user input we want to default to office hours, if the hour is zero there is nothing to interpret
                if (hour >= 7 && hour <= 11) {
                    isAM = true; //It's AM if our hour is 7 to 11, it's PM if our hour is 12 to 6.
                } 
            }
            else { //If we aren't interpreting based on office hours
                isAM = hour < 12; //It's AM if it's before 12
            }

            ampm = isAM ? "AM" : "PM";
        }
        else {
            isAM = ampm == "AM";
        }
        
        let fullHour = hour;
        //For 24 hour time... 
        //12 AM is actually 00:00.
        if (isAM && hour == 12) {
            fullHour = 0;
        } //and anything after 12PM is actually the hour + 12 (ex: 2:00PM is 14:00)
        else if (!isAM && hour < 12) {
            fullHour = hour + 12;
        }

        //For 12 hour display, we need to subtract 12 from hours over 12 and hour 0 is actually 12
        const displayHour = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;
    
        return { fullTime: this.padTimeZeroes(fullHour) + ":" + this.padTimeZeroes(minutes),  displayTime: displayHour + ":" + this.padTimeZeroes(minutes) + " " + ampm };   
    }

    /**
     * Adds a "0" in front of single digit numbers
     * @param timePart The number to add to
     */
    private padTimeZeroes(timePart : number ) {
        var textTimePart = timePart.toString();
        if (textTimePart.length == 1) {
            return "0" + textTimePart;
        }
        
            return textTimePart;
    }

    get times() : TimeItem[] {
        var amTimes = this.build12hourTimes("AM");
        var pmTimes = this.build12hourTimes("PM");

        this.resultCount = amTimes.length + pmTimes.length;

        return amTimes.concat(pmTimes);
    }

    timeClicked(time : TimeItem) {
        this.setTime(time);
    }

    private setTime(time : TimeItem | null) {
        this.storedTime = time;
        this.inputTime = time ? time.displayTime  : "";
        this.showDropdown = false;
        this.emitInput();
    }

   

    private build12hourTimes(ampm : string) : TimeItem[] {

        var maxMinutes : number = 60;
        var maxHour : number = 11;
        var times : TimeItem[] = [];

        for (var hour = 0; hour <= maxHour; hour++) { 
            for (var minute = 0; minute < maxMinutes; minute = minute + this.minuteInterval) { 
                var textHour = hour == 0 ? "12" : hour.toString();
                var textMinute = minute == 0 ? "00" : minute.toString();

                var displayTime = textHour + ":" + textMinute + " " + ampm;
                
                var pmOffset = ampm == "PM" ? 12 : 0;
                var fullHour = (hour + pmOffset);
                var fullTime = (fullHour < 10 ? "0" : "") + fullHour + ":" + textMinute;

                times.push({ fullTime, displayTime });
            }
        }

        return times;
    }

    downKeyPress() {
        if (this.readOnly)
            return;
             
        if (!this.showDropdown)
            this.showDropdown = true;
        else
            this.pointerForward();
    }
}