































































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { DtoDegree, createDtoDegree } from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';

@Component({
    components: {
        FieldValidationErrors
    }
})
export default class Education extends Vue {
    @Prop({required: true, default: () => createDtoDegree()})
    degrees!: DtoDegree[];

    @Prop({required: false, default: Math.random().toString(36).substr(2, 9)})
    idPrefix!: string;

    @Inject('validator') $validator: any;

    // Private variables
    degree: DtoDegree = createDtoDegree();

    get elementIds() {
        const ids = {
            school: `${this.idPrefix}_education_school`,
            degreeName: `${this.idPrefix}_education_degree-name`,
            yearObtained: `${this.idPrefix}_education_year-obtained`
        };
        return ids;
    }

    addDegree() {
        if (!this.degree.school && this.errors.has(this.elementIds['school']) === false) {
            this.errors.add({
                field: this.elementIds['school'],
                msg: 'Please provide a school'
            });
        }
        else if (this.degree.school) {
            this.errors.remove(this.elementIds['school']);
        }

        if (!this.degree.name && this.errors.has(this.elementIds['degreeName']) === false) {
            this.errors.add({
                field: this.elementIds['degreeName'],
                msg: 'Please provide a degree'
            });
        }
        else if (this.degree.name) {
            this.errors.remove(this.elementIds['degreeName']);
        }

        if (!this.degree.year && this.errors.has(this.elementIds['yearObtained']) === false) {
            this.errors.add({
                field: this.elementIds['yearObtained'],
                msg: 'Please provide a year'
            });
        }
        else if (this.degree.year) {
            this.errors.remove(this.elementIds['yearObtained']);
        }

        if (this.errors.any() === false) {
            this.degrees.push(this.degree);
            this.degree = createDtoDegree();
        }
    }

    deleteDegree(deletedDegree: DtoDegree) {
        var indexToDelete = -1;
        this.degrees.some(function (el: any, i: any): boolean {
            if (indexToDelete === -1 &&
                el.id == deletedDegree.id &&
                el.name == deletedDegree.name &&
                el.school == deletedDegree.school &&
                el.year == deletedDegree.year) {
                indexToDelete = i;
                return true;
            }

            return false;
        });

        this.degrees.splice(indexToDelete, 1);
    }

    mounted() {
        
    }

    create() {            
    }
}
