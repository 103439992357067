import { PaymentTypeEnum } from './PaymentTypeEnum';
import { SavedAch } from './SavedAch';
import { SavedCreditCard } from './SavedCreditCard';

export interface PaymentProcessorOptions {
    //Optional options
    paymentType: PaymentTypeEnum;
    enableInvoice: boolean;
    enableCreditCard: boolean;
    enableSaveCreditCard: boolean;
    enableAutoPay: boolean;
    savedCards: SavedCreditCard[];
    enableACH: boolean;
    enableSaveAch: boolean;
    savedAchs: SavedAch[];
    showSubmitButton: boolean;
    submitButtonText: string;
    submitButtonSavingText: string;
    paymentLabelText: string;
    disabledButtonText: string;
    billablePartyId: number;
    invoiceWarningMessage: string;
    invoiceFee: number;
    donateTransactionFeeLabel: string;
    donateTransactionFeeDescription: string;
    donateTransactionFeePercentage: number | null;
    donateTransactionFeeChargeType: string;
    initialTotal: number | null;
    initialGuestName: string;
    initialGuestCompany: string;
    autoPayRequired: boolean;
    hasItemWithDisabledInvoicing: boolean;
    customerForBillableParties: number | null;
    unauthorized: boolean;
    purchaserPersonId: number;
    purchaserCompanyId: number;
    taxRate: number;

    //Admin options
    enableAdminOptions: boolean;
    salesTermId: number | null;
    hideTerms: boolean;
    searchableBillableParties: boolean;
    defaultPaymentTermDate: Date | null;

    alternatePaymentStripeId: string | null;
    alternatePaymentCurrency: string | null;
}

export function createPaymentProcessorOptions(instance?: PaymentProcessorOptions | any) {
    const obj = <PaymentProcessorOptions>{
        paymentType: PaymentTypeEnum.creditCard,
        enableAdminOptions: false,
        enableCreditCard: true,
        enableACH: false,
        enableSaveCreditCard: false,
        enableAutoPay: false,
        savedCards: [],
        enableSaveAch: false,
        savedAchs: [],
        enableInvoice: true,
        showSubmitButton: true,
        submitButtonText: '',
        submitButtonSavingText: '',
        paymentLabelText: 'Payment',
        disabledButtonText: '',
        billablePartyId: 0,
        invoiceWarningMessage: '',
        salesTermId: null,
        hideTerms: true,
        searchableBillableParties: false,
        invoiceFee: 0,
        donateTransactionFeeLabel: '',
        donateTransactionFeeDescription: '',
        donateTransactionFeePercentage: 0,
        donateTransactionFeeChargeType: '',
        initialTotal: null,
        initialGuestName: '',
        initialGuestCompany: '',
        autoPayRequired: false,
        hasItemWithDisabledInvoicing: false,
        defaultPaymentTermDate: null,
        customerForBillableParties: null,
        unauthorized: false,
        purchaserCompanyId: 0,
        purchaserPersonId: 0,
        taxRate: 0,
        alternatePaymentStripeId: null,
        alternatePaymentCurrency: null
    }

    if (instance) {
        Object.assign(obj, instance);
    }

    return obj;
}