import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ValidationErrors'
})
export default class ValidationErrors extends Vue {
    @Prop({ required: true })
    errorList!: string[];

    @Prop({ required: false, default: () => "There are some issues with your information, please correct them before moving on." })
    baseMessage!: string;
}