




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CharacterCount extends Vue {
    @Prop({required: true, default: () => 0})
    maxCount!: number;

    @Prop({ required: true, default: () => "" })
    text!: string;

    inputFocus: boolean = false;

    get charactersRemaining() {

        var numCharactersRemaining = this.text ? this.maxCount - this.text.length : this.maxCount;
        return numCharactersRemaining + " characters remaining";
    }

    create() {
    }

    mounted() {
        var input = this.$el.previousElementSibling;
        var vm = this;

        if (input && (input.tagName.toLowerCase() == "input" || input.tagName.toLowerCase() == "textarea")) {
            input.addEventListener("focus", function () {
                vm.inputFocus = true;
            });

            input.addEventListener("blur", function () {
                vm.inputFocus = false;
            });
        }
    }
}
