import { DirectiveOptions } from 'vue';

const ariaSelectedByClassName: DirectiveOptions = {
    update: function (el, binding) {
        var ariaSelectedElements = $(el).find("[aria-selected='true']");
        ariaSelectedElements.attr("aria-selected", "false");

        if ($(el).hasClass(binding.value)) {
            $(el).attr("aria-selected", "true");
        } else {
            $(el).find(binding.value).attr("aria-selected", "true");
        }
    }
};

export default ariaSelectedByClassName;