import { Component, Vue, Prop, Inject, Emit } from 'vue-property-decorator';
import { DtoUserAccount, createDtoUserAccount } from '@/common/types/AllTypesExports';
import DuplicateChecker from './DuplicateChecker.vue';
import CharacterCount from '../../components/inputs/CharacterCount.vue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { customerRepo } from '../../common/repositories/CustomerRepo';
import debounce from 'lodash/debounce';

@Component({
    components: {
        CharacterCount,
        DuplicateChecker,
        FieldValidationErrors
    },
})
export default class AccountInfo extends Vue {

    @Prop({ required: true, default: () => createDtoUserAccount() })
    userAccount!: DtoUserAccount;

    @Prop({ required: false, default: true })
    allowEditNameFields!: boolean;

    showPassword: boolean = false;
    agreesToTerms: boolean = false;
    isPasswordCompromised: boolean = true;

    @Inject('validator')
    $validator: any;

    //Computed Properties
    get passwordsMatch() {
        if (!this.userAccount.password || !this.userAccount.passwordConfirm)
            return false;

        return this.userAccount.password == this.userAccount.passwordConfirm;
    }

    get passwordEnoughCharacters() {
        return this.userAccount.password.length >= 8;
    }

    get passwordHasUppercaseLetter() {
        var letters = /\w*[A-Z]\w*/;
        return this.userAccount.password.match(letters) !== null;
    }

    get passwordHasNumber() {
        var numbers = /\w*[0-9]\w*/;
        return this.userAccount.password.match(numbers) !== null;
    }

    get passwordHasSpecialCharacter() {
        var noLettersNumbersOrWhiteSpace = /[^A-Z a-z0-9]/;
        return this.userAccount.password.match(noLettersNumbersOrWhiteSpace) !== null;
    }

    get passwordHasEmail() {
        if (!this.userAccount.password || !this.userAccount.email)
            return true;
        return this.userAccount.email.indexOf(this.userAccount.password) >= 0 || this.userAccount.password.indexOf(this.userAccount.email) >= 0;
    }

    get passwordIsValid() {
        // If we haven't put in enough characters then skip this check.
        if (!this.passwordEnoughCharacters) {
            return true;
        }

        return this.passwordHasUppercaseLetter && this.passwordHasNumber && this.passwordHasSpecialCharacter;
    }

    get passwordInputType() {
        return this.showPassword ? "text" : "password";
    }
    get toggleShowPasswordTitle() {
        if(this.showPassword)
            return 'Hide Password';
        return 'Show Password';
    }

    //Life Cycle
    created() {
        this.$validator.extend('passwordHasEmail', {
            getMessage: () => 'Password cannot be a part of your email address.',
            validate: () => !this.passwordHasEmail
        }, { hasTarget: true });

        this.$validator.extend('passwordIsValid', {
            getMessage: () => 'Password is not valid.',
            validate: () => this.passwordIsValid
        });

        this.$validator.extend('isPasswordCompromised', {
            getMessage: () => 'The password entered appears on a list of common or compromised passwords. Please change it to continue.',
            validate: () => !this.isPasswordCompromised
        });

        this.$validator.extend('passwordHasEmail', {
            getMessage: () => 'The password cannot be part of your email.',
            validate: () => !this.passwordHasEmail
        });

        this.$validator.extend('agreeToTerms', {
            getMessage: () => 'You must agree to terms & conditions to continue.',
            validate: (value : boolean ) => value
        }, { validatesRequired: true });
    }

    mounted() {
        var firstInput = this.$el.querySelector("input");
        if (firstInput) {
            (<HTMLElement>firstInput).focus();
        }

        this.validatePasswordSecurity = debounce(this.validatePasswordSecurity, 500);
    }

    //Functions
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    onEmailChange() {
        this.emitUserAccount();
        this.emitEmailUpdated();

        if (this.userAccount.email && this.userAccount.password) {
            // We need to validate here so that Password field is checked against the updated Email
            this.validatePasswordSecurity()
        }
    }

    emitEmailUpdated() {
        this.$emit('update:email', this.userAccount.email);
    }

    emitUserAccount() {
        const userToEmit = createDtoUserAccount();
        this.$emit('update:userAccount', Object.assign(userToEmit, this.userAccount));
    }

    validatePasswordSecurity() {
        if (!this.passwordEnoughCharacters) {
            this.isPasswordCompromised = true;
            return;
        }

        this.isPasswordCompromised = false;

        customerRepo.isPasswordCompromised(this.userAccount.password).then(result => {
            this.isPasswordCompromised = result.isPasswordCompromised;

            if (this.$refs.account_password) {
                this.$validator.validate('account_password');
            }
        });
    }
}