



















import { Component, Vue, Prop, Emit, Watch, Inject } from 'vue-property-decorator';
import { CustomField } from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';

@Component({
    components: {
        FieldValidationErrors
    },
})
export default class CustomFieldCheckboxEditor extends Vue {
    //External
    @Prop({required: true})
    fieldConfig!: CustomField;
    @Prop({required: true})
    value?: string;
    @Prop({ required: false, default: false})
    readOnly!: boolean;
    @Prop({ required: true })
    id!: string;
    @Prop({ required: false, default: ''})
    validatorScope!: string;
    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;
    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    @Watch('value', { immediate: true })
    onValueChanged(val: string, oldVal: string) { 
        if (typeof val === 'string') {
            this.localValue = (val && val.toLowerCase() == 'true') || false;
        }
        else {
            this.localValue = val ? true : false;
        }
    }

    localValue: boolean = false;

    @Emit('input')
    emitInput() {
        return this.localValue;
    }

    get rules() {
        return {
            required: this.required,
        }
    }

    get required() {
        return (this.isRequired == null && this.fieldConfig.requiredFrontend && !this.readOnly) || (this.isRequired != null && this.isRequired);
    }

    get showRestrictionsMessage() {
        return this.readOnly && !this.hideDisabledMessage;
    }
}
