export interface DtoAddress {
    id: number;
    address1: string | null;
    address2: string | null;
    city: string | null;
    zipCode: string | null;
    stateProvince: string | null;
    country: string | null;
}


export function createDtoAddress(obj?: DtoAddress | any) {
    const result = <DtoAddress>{
        id: 0,
        address1: null,
        address2: null,
        city: null,
        zipCode: null,
        stateProvince: null,
        country: null
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}