import { LogMessageType } from './LogTypeEnum';
import dayjs from 'dayjs';

export interface LogEntry {
    timeStamp: string | Date;
    logMessageType: LogMessageType;
    message: string;
    errorExc: Error | null;
    customData: any;
    username: string;
}

export function createLogEntry(obj?: LogEntry | any) {
    const result = <LogEntry>{
        logMessageType: LogMessageType.Info,
        message: '',
        errorExc: null,
        timeStamp: dayjs().format(),
        customData: null,
        username: ''
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}