
export interface ApiErrorResponse {
    message: string;
    errorDetails: string;
    links: { [key: string]: string };
}

export function createApiErrorResponse(obj?: ApiErrorResponse | any) {
    const result = <ApiErrorResponse>{
        errorDetails: '',
        links: {},
        message: ''
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}