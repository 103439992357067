



















































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { DtoAward, createDtoAward } from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { Multiselect } from 'vue-multiselect';

@Component({
    components: {
        Multiselect,
        FieldValidationErrors
    },
})
export default class Awards extends Vue {
    @Prop({ required: true, default: () => createDtoAward()})
    awards!: DtoAward[];

    @Prop({ required: false, default: Math.random().toString(36).substr(2, 9)})
    idPrefix!: string;

    // Private variables
    award: DtoAward = createDtoAward();
    years: string[] = [];

    get elementIds() {
        const ids = {
            awardName: `${this.idPrefix}_awards_award-name`,
            awardYears: `${this.idPrefix}_awards_award-years`
        };

        return ids;
    }

    addYear(newYear: string) {
        this.years.push(newYear);
        this.award.year = this.getYearString();

        if (this.errors.has(this.elementIds['awardYears'])) {
            this.errors.remove(this.elementIds['awardYears']);
        }
    }

    removeYear(removedYear: string) {
        this.years = this.years.filter(year => year !== removedYear);
        this.award.year = this.getYearString();
    }

    getYearString() {
        return this.years.join(" & ");
    }

    addAward() {
        if (this.award.name == "" && this.errors.has(this.elementIds['awardName']) == false) {
            this.errors.add({
                field: this.elementIds['awardName'],
                msg: 'Please provide an award name'
            });
        }
        else if (this.award.name != "") {
            this.errors.remove(this.elementIds['awardName']);
        }

        if (this.award.year == "" && this.errors.has(this.elementIds['awardYears']) == false) {
            this.errors.add({
                field: this.elementIds['awardYears'],
                msg: 'Please provide at least one year'
            });
        }
        else if (this.years.length > 0) {
            this.errors.remove(this.elementIds['awardYears']);
        }

        if (this.errors.any() === false)
        {
            this.awards.push(this.award);
            this.award = createDtoAward();
            this.years = [];
        }
    }

    deleteAward(deletingAward: DtoAward) {
        var indexToDelete = -1;
        this.awards.some(function (el: any, i: any): boolean {
            if (indexToDelete === -1 &&
                el.id == deletingAward.id &&
                el.name == deletingAward.name &&
                el.year == deletingAward.year) {
                indexToDelete = i;
                return true;
            }

            return false;
        });

        this.awards.splice(indexToDelete, 1);
    }

    create() {            
    }
}
