import { Component, Vue, Prop, Provide, Emit, Watch, Inject } from 'vue-property-decorator';
import { PropOptions } from 'vue';
import CreditCardContainer from './CreditCardContainer.vue';
import InvoiceContainer from './InvoiceContainer.vue';
import AchContainer from './AchContainer.vue';
import { default as InvoiceContainerClass } from '@/components/payment-container/InvoiceContainerVue';
import { default as CreditCardContainerClass } from '@/components/payment-container/CreditCardContainerVue';
import { default as AchContainerClass } from '@/components/payment-container/AchContainerVue';
import { createPaymentProcessorOptions, PaymentProcessorOptions, PaymentContainerToken, createPaymentContainerToken, InvoiceTokenData, CreditCardTokenData, PaymentTypeEnum, AchTokenData } from '@/common/types/AllTypesExports';
import EventBus from '../../common/services/EventBus';
import { ApiPaymentErrorResponse } from '@/common/types/Payment/ApiPaymentErrorResponse';
import { PaymentProcessingStatus } from '@/common/types/Payment/PaymentProcessingStatus';
import ErrorAlert from '../../components/errors/ErrorAlert.vue';
import Validation from '../../common/services/ValidationService';

@Component({
    name: 'PaymentContainer',
    components: {
        CreditCardContainer,
        InvoiceContainer,
        AchContainer,
        ErrorAlert
    }
})
export default class PaymentContainer extends Vue {
    @Prop({ required: true, default: () => createPaymentProcessorOptions() } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, default: false })
    autoPayRequired!: boolean;

    @Prop({ required: false, default: undefined })
    initialErrorMessage: string | undefined;

    @Prop({ required: false, default: () => 0 })
    proposedDonateTransactionFeeAmount: number = 0;

    isDonatingTransactionFee: boolean = false;

    @Inject('validator')
    $validator: any;

    creditCardActive = false;
    invoiceActive = false;
    achActive = false;
    paymentContainerToken: PaymentContainerToken = createPaymentContainerToken(<PaymentContainerToken>{ paymentType: this.paymentType });
    isProcessing = true;
    hasError = false;
    preventCreditCard = false;
    creditCardLocked = false;
    cardRestrictionMessage = "";
    invoiceLocked = false;
    preventAch = false;
    achLocked = false;
    errorMessage = '';
    total: number | null = this.processorOptions.initialTotal;
    guestName: string = this.processorOptions.initialGuestName;
    guestCompany: string = this.processorOptions.initialGuestCompany;
    forceSaveCard = false;
    forceSaveAch = false;
    hasItemWithDisabledInvoicing = false;
    disabledInvoiceTitle = '';
    billablePartiesNotLoadedErrorMessage = 'Please select a Bill To party for this transaction.';

    private currentPaymentType: PaymentTypeEnum = PaymentTypeEnum.creditCard;
    private localEnableInvoice: boolean = true;

    get free() {
        return this.total === null || this.total === 0;
    }

    get showTabs() {
        let numberOfTabs = 0;
        if (this.processorOptions.enableInvoice) {
            numberOfTabs++;
        }
        if (this.processorOptions.enableCreditCard) {
            numberOfTabs++;
        }
        if (this.processorOptions.enableACH) {
            numberOfTabs++;
        }

        return numberOfTabs > 1;
    }

    @Watch("processorOptions.enableACH")
    updatePaymentType() {
        if (this.processorOptions.enableACH) {
            this.toggleTabActivation(PaymentTypeEnum.ach);
        }
        else if (this.processorOptions.enableCreditCard) {
            this.toggleTabActivation(PaymentTypeEnum.creditCard);
        }
    }

    get paymentType(): PaymentTypeEnum {
        if (this.currentPaymentType)
            return this.currentPaymentType;

        if (this.invoiceActive) {
            return PaymentTypeEnum.invoice;
        }
        else if (this.achActive) {
            return PaymentTypeEnum.ach;
        }
        else {
            return PaymentTypeEnum.creditCard;
        }
    }

    set paymentType(value: PaymentTypeEnum) {
        this.currentPaymentType = value;
    }

    get isInvoiceEnabled() {
        return this.processorOptions.enableInvoice && this.localEnableInvoice;
    }

    get convenienceFee() {
        return !this.free && this.invoiceActive && !this.paymentContainerToken.invoiceTokenData.waiveInvoiceFee ? this.processorOptions.invoiceFee : 0;
    }

    get isSubmitDisabled() {
        if (this.disabled)
            return true;

        if (this.isProcessing)
            return true;


        if (this.total && this.total > 0) {
            if (this.paymentType == PaymentTypeEnum.creditCard && (!this.processorOptions.enableCreditCard || this.creditCardLocked || this.cardRestrictionMessage || this.preventCreditCard))
                return true;

            if (this.paymentType == PaymentTypeEnum.ach && this.preventAch)
                return true;

            if (this.paymentType == PaymentTypeEnum.invoice && (!this.isInvoiceEnabled || this.invoiceLocked))
                return true;
        }

        if (this.total == null)
            return true;

        // This is a special check for donations inputs on the checkout that could be in error but are not being propagated to the container. 
        // To accomidate this we will do a check if there are any errors with donation inputs at this scope and lock the button if so. 
        if (this.errors.items.filter(x => x.field != null && x.field.includes("_amount")).length > 0)
            return true;

        return false;
    }

    get processingText() {
        return this.processorOptions.submitButtonSavingText 
            ? this.processorOptions.submitButtonSavingText
            : 'Processing...';
    }

    get submitButtonText() {
        return this.processorOptions.submitButtonText
            ? this.processorOptions.submitButtonText
            : 'Complete Registration';
    }

    get disabledText() {
        if (this.isProcessing)
            return this.processingText;
		else if (this.creditCardLocked || this.invoiceLocked)
			return this.submitButtonText;
        else if (this.processorOptions.disabledButtonText)
            return this.processorOptions.disabledButtonText;
        else 
            return this.submitButtonText;
    }

    onSubmit() {
        var validationPassed = false;

        this.$validator.validateAll()
            .then((valid: any) => {
                if (valid && !this.errors.any()) {
                    validationPassed = true;
                    this.onErrorToggle(false, '');
                }
                else {

                    if (!this.errors.any()) {
                        validationPassed = true;
                        this.onErrorToggle(false, '');
                    }
                    else {
                        validationPassed = false;
                        this.onErrorToggle(true, 'Please correct the errors shown before submitting.');
                    }
                }

                if (this.currentPaymentType == PaymentTypeEnum.creditCard) {
                    let creditCardContainer = <CreditCardContainerClass>this.$refs.creditCardContainer;
                    if ((this.autoPayRequired || (this.processorOptions.enableAutoPay && this.processorOptions.autoPayRequired)) && creditCardContainer && !creditCardContainer.autoPay) {
                        validationPassed = false;
                        this.onErrorToggle(true, 'Enrollment in auto-pay is required for this member type. Please check “Auto-Pay Future Membership Renewals” to continue.');
                    }

                    if (this.total && this.total > 0 && creditCardContainer.isCheckingForBillableParties) {
                        validationPassed = false;
                        this.onErrorToggle(true, this.billablePartiesNotLoadedErrorMessage);
                    }
                }
                else if (this.currentPaymentType == PaymentTypeEnum.ach) {
                    let achContainer = <AchContainerClass>this.$refs.achContainer;

                    if (this.total && this.total > 0 && achContainer.isCheckingForBillableParties) {
                        validationPassed = false;
                        this.onErrorToggle(true, this.billablePartiesNotLoadedErrorMessage);
                    }
                }

                else if (this.currentPaymentType == PaymentTypeEnum.invoice) {
                    let invoiceContainer = <InvoiceContainerClass>this.$refs.invoice;

                    if (this.total && this.total > 0 && invoiceContainer.isCheckingForBillableParties) {
                        validationPassed = false;
                        this.onErrorToggle(true, this.billablePartiesNotLoadedErrorMessage);
                    }
                }
            })
            .then(() => {
                if (!validationPassed)
                    return;

                this.onProcessingUpdated(true);
                this.paymentContainerToken.paymentType = this.free ? PaymentTypeEnum.free : this.paymentType;

                if (this.paymentType === PaymentTypeEnum.invoice || this.free) {
                    this.tokenReady();
                }
                else if (this.paymentType == PaymentTypeEnum.creditCard) {
                    const creditCardContainer = <CreditCardContainerClass>this.$refs.creditCardContainer;
                    creditCardContainer.createPaymentToken();
                }
                else if (this.paymentType == PaymentTypeEnum.ach) {
                    const achContainer = <AchContainerClass>this.$refs.achContainer;
                    achContainer.createPaymentToken();
                }
            });
    }

    onErrorToggle(hasError: boolean, errorMsg: string) {
        if (!hasError) {
            this.hasError = false;
            this.errorMessage = '';
        }
        else {
            this.hasError = true;
            this.onProcessingUpdated(false);
            this.errorMessage = errorMsg ? errorMsg : 'An unspecified error has occurred. Please retry your current action. If this problem persists, please contact support.';
        }
    }

    handleGeneralError(message: string) {
        this.onErrorToggle(true, message);
    }

    onAllowSubmitToggle(allowSubmit: boolean) {
        this.creditCardLocked = !allowSubmit;
        this.invoiceLocked = !allowSubmit;
    }

    onForceSaveCardUpdated(forceSaveCard: boolean) {
        this.forceSaveCard = forceSaveCard;
    }

    onForceSaveAchUpdated(forceSaveAch: boolean) {
        this.forceSaveAch = forceSaveAch;
    }

    onCreditCardRestrictionUpdated(cardRestrictionMessage: string) {
        this.cardRestrictionMessage = cardRestrictionMessage;
    }

    onInvoiceWarningUpdated(message: string) {
        this.processorOptions.invoiceWarningMessage = message;
    }

    onTotalUpdated(total: number) {
        this.total = total;
    }

    onProposedDonateTransactionFeeAmountUpdated(value: number) {
        this.proposedDonateTransactionFeeAmount = value;
    }

    onEnableAutoPayUpdated(enableAutoPay: boolean) {
        this.processorOptions.enableAutoPay = enableAutoPay;
    }
    
    onProcessingUpdated(processing: boolean) {
        this.isProcessing = processing;
        this.$emit("update:is-processing", this.isProcessing);
    }

    onCreditCardBillingStateUpdate(state: string) {
        this.$emit("update:credit-card-billing-state", state);
    }

    onIsDonatingTransactionFeeUpdated(value: boolean) {
        this.$emit("update-is-donating-transaction-fee", value);
        this.isDonatingTransactionFee = value;
    }

    onPurchaserChanged(value: string) {
        this.processorOptions.billablePartyId = parseInt(value, 10);
    }

    @Watch('convenienceFee')
    onConvenienceFeeChanged(newConvenienceFee: boolean) {
        this.$emit("update:convenience-fee", newConvenienceFee);
    }

    @Watch('paymentType')
    onPaymentTypeChanged(newPaymentType: PaymentTypeEnum) {
        this.$emit("update:payment-type", newPaymentType.toString());
        this.$nextTick(() => {
            if (newPaymentType.toString() == 'ACH') {
                const achContainer = <AchContainerClass>this.$refs.achContainer;
                if (achContainer) {
                    if (this.guestName) {
                        achContainer.onGuestDataUpdated(this.guestName, true);
                    }
                    if (this.guestCompany) {
                        achContainer.onGuestDataUpdated(this.guestCompany, false);
                    }
                }
            }
            if (newPaymentType.toString() == 'credit-card') {
                const creditCardContainer = <CreditCardContainerClass>this.$refs.creditCardContainer;
                if (creditCardContainer) {
                    if (this.guestName) {
                        creditCardContainer.onGuestDataUpdated(this.guestName, true);
                    }
                    if (this.guestCompany) {
                        creditCardContainer.onGuestDataUpdated(this.guestCompany, false);
                    }
                }
            }
        });
    }


    @Watch('errorMessage')
    onErrorMessageChanged(newErrorMessage: boolean) {
        this.$emit("update:error-message", newErrorMessage);
    }

    @Watch('free')
    onFreeChange(newFree: boolean) {
        if (newFree) {
            //If we are going free, switch to next available payment method so no invoicing blocking rules affect checkout

            if (this.creditCardActive) {
                this.toggleTabActivation(PaymentTypeEnum.creditCard);
            }
            else if (this.achActive) {
                this.toggleTabActivation(PaymentTypeEnum.ach);
            }
            else {
                this.toggleTabActivation(PaymentTypeEnum.creditCard); // fallback
            }
        }
    }

    handleApiPaymentError(paymentResponse: ApiPaymentErrorResponse) {
        this.onErrorToggle(true, paymentResponse.message);
        switch (paymentResponse.paymentProcessingStatus) {
            case PaymentProcessingStatus.Success:
            case PaymentProcessingStatus.Error:
                //Success: Payment was successful, but we caught an error so something else went wrong and we can't retry
                //Error: Payment had an error, we don't know the status and we can't retry
                this.creditCardLocked = true;
                this.invoiceLocked = true;
                break;      
            case PaymentProcessingStatus.Declined:
                //Declined: Payment was declined/not processed for the given reason, need to fix and retry
                this.resetIdempotencyKey();
                break;
            case PaymentProcessingStatus.None:                
                //None: We hit an error before payment was attempted - fully safe to retry                
                break;
        }
    }

    onInvoiceAllowedUpdated(invoiceAllowed: boolean) {
        this.localEnableInvoice = invoiceAllowed;
    }

    onIsCreditCardProcessingPreventedUpdated(value: boolean) {
        this.preventCreditCard = value;
    }

    onIsAchProcessingPreventedUpdated(value: boolean) {
        this.preventAch = value;
    }

    onInvoiceTokenUpdated(invoiceToken: InvoiceTokenData) {
        var taxableChanged = invoiceToken.taxable != this.paymentContainerToken.invoiceTokenData.taxable;
        //console.log(`Invoice Token Updated: (Billable Party: ${invoiceToken.billablePartyId}) (Sales Term: ${invoiceToken.salesTermId}) (Waive Invoice Fee: ${invoiceToken.waiveInvoiceFee})`);
        Object.assign(this.paymentContainerToken.invoiceTokenData, invoiceToken);

        if (taxableChanged) {
            this.$emit("update:billable-party-taxable", invoiceToken.taxable);
        }
    }

    onCreditCardTokenUpdated(creditCardToken: CreditCardTokenData) {
        Object.assign(this.paymentContainerToken.creditCardTokenData, creditCardToken);
    }

    onAchTokenUpdated(achToken: AchTokenData) {
        Object.assign(this.paymentContainerToken.achTokenData, achToken);
    }

    onCreditCardPaymentTokenReady(cardToken: CreditCardTokenData) {
        //console.log("Credit card token received by payment container: " + cardToken.cardToken);
        this.paymentContainerToken.paymentType = PaymentTypeEnum.creditCard;

        // If the credit card details changed since the last submission, then we need to send a new idempotency key
        // Note: this is really just for stripe, which returns an error if you re-submit a payment with the same idempotency key but different card details
        // QB Payments ignores the payment details if you re-submit with the same idempotency key and returns whatever response it gave the last time
        if (this.paymentContainerToken.creditCardTokenData.storedCardId != cardToken.storedCardId || this.paymentContainerToken.creditCardTokenData.cardToken != cardToken.cardToken) {
            this.resetIdempotencyKey();         
        }

        Object.assign(this.paymentContainerToken.creditCardTokenData, cardToken);
        this.tokenReady();
    }

    onAchPaymentTokenReady(cardToken: AchTokenData) {
        this.paymentContainerToken.paymentType = PaymentTypeEnum.ach;

        Object.assign(this.paymentContainerToken.achTokenData, cardToken);
        this.tokenReady();
    }
    
    toggleTabActivation(paymentType: PaymentTypeEnum) {
        if (this.hasItemWithDisabledInvoicing && paymentType === PaymentTypeEnum.invoice) {
            return;
        }
        this.creditCardActive = paymentType === PaymentTypeEnum.creditCard || paymentType == PaymentTypeEnum.free;
        this.invoiceActive = paymentType === PaymentTypeEnum.invoice;
        this.achActive = paymentType === PaymentTypeEnum.ach;
        this.paymentType = paymentType;
    }

    @Emit('payment-token-ready')
    tokenReady() {        
        return this.paymentContainerToken;
    }

    // Borrowed from https://stackoverflow.com/a/2117523/215141
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    resetIdempotencyKey() {
        this.paymentContainerToken.idempotencyKey = this.uuidv4();
    }

    created() {
        EventBus.$on('api-payment-error', this.handleApiPaymentError);
    }

    mounted() {
        this.selectDefaultPaymentTab();
        this.isProcessing = false;
        this.localEnableInvoice = !this.processorOptions.invoiceWarningMessage;
        this.hasItemWithDisabledInvoicing = this.processorOptions.hasItemWithDisabledInvoicing;
        this.disabledInvoiceTitle = this.hasItemWithDisabledInvoicing ? 'One or more attendee tickets has invoicing disabled.' : ''
        this.resetIdempotencyKey();

        if (this.initialErrorMessage) {
            this.onErrorToggle(true, this.initialErrorMessage)
        }
    }

    selectDefaultPaymentTab() {
        // Default states
        this.creditCardActive = false;
        this.achActive = false;
        this.invoiceActive = false;

        const defaultCreditCardExists = this.processorOptions.enableCreditCard &&
            this.processorOptions.savedCards &&
            this.processorOptions.savedCards.some(card => card.default);

        const defaultAchExists = this.processorOptions.enableACH &&
            this.processorOptions.savedAchs &&
            this.processorOptions.savedAchs.some(ach => ach.default);

        if (defaultCreditCardExists) {
            this.creditCardActive = true;
        } else if (defaultAchExists) {
            this.achActive = true;
        } else if (this.processorOptions.enableCreditCard) {
            this.creditCardActive = true;
        } else if (this.processorOptions.enableACH) {
            this.achActive = true;
        } else if (this.processorOptions.enableInvoice) {
            this.invoiceActive = true;
        } else {
            this.creditCardActive = true;
        }

        // Handle payment type setting on page load
        if (this.achActive) {
            this.toggleTabActivation(PaymentTypeEnum.ach);
        }
        else if (this.creditCardActive) {
            this.toggleTabActivation(PaymentTypeEnum.creditCard);
        }
        else if (this.invoiceActive) {
            this.toggleTabActivation(PaymentTypeEnum.invoice);
        }
    }

    onGuestNameUpdated(name: string) {
        if (name) {
            this.guestName = name;
            let creditCardContainer = <CreditCardContainerClass>this.$refs.creditCardContainer;
            if (creditCardContainer) {
                creditCardContainer.onGuestDataUpdated(name, true);
            }
            let achContainer = <AchContainerClass>this.$refs.achContainer;
            if (achContainer) {
                achContainer.onGuestDataUpdated(name, true);
            } 
        }               
    }

    onGuestCompanyUpdated(company: string) {
        if (company) {
            this.guestCompany = company;
            let creditCardContainer = <CreditCardContainerClass>this.$refs.creditCardContainer;
            if (creditCardContainer) {
                creditCardContainer.onGuestDataUpdated(company, false);
            }
            let achContainer = <AchContainerClass>this.$refs.achContainer;
            if (achContainer) {
                achContainer.onGuestDataUpdated(company, false);
            } 
        }        
    }
}