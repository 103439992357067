
export interface GoogleAddressComponentNameMap {
    [key: string]: string;
    street_number: string;
    subpremise: string;
    route: string;
    locality: string;
    postal_town: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    postal_code_prefix: string;
}

export function createGoogleAddressComponentNameMap(paramObj?: GoogleAddressComponentNameMap | any){
    const obj = <GoogleAddressComponentNameMap> {
        street_number: "short_name",
        subpremise: "long_name",
        route: "long_name",
        locality: 'long_name',
        postal_town: 'long_name', 
        administrative_area_level_1: 'long_name',
        country: 'long_name',
        postal_code: 'short_name',
        postal_code_prefix: 'long_name'
    };

    if(paramObj){
        Object.assign(obj, paramObj);
    }
    return obj;
}