import { DtoCustomer, createDtoCustomer } from '../types/membership/DtoCustomer';
import { CustomerFieldConfig, DtoMemberType, PaymentContainerToken, DtoUserAccount, DuplicateCheckResult, DtoAddress } from '../types/AllTypesExports';
import BaseNoviApiRepo from './BaseNoviApiRepo';
import { DtoDuesLineItemResponse } from '../types/membership/DtoDuesLineItemResponse';
import { DtoProductQuantity } from '../types/ecommerce/DtoProductQuantity';
import { SelectListItemWithSubtext } from '../types/SelectListItemWithSubtext';
import { CouponValidationResult } from '../types/membership/CouponValidationResult';
import { DefaultMemberResult } from '../types/membership/DefaultMemberResult';
import { DtoCustomerCreateResponse } from '../types/membership/DtoCustomerCreateResponse';
import { DtoCurrentUser } from '../types/user/DtoCurrentUser';


/**
 * @description Handles API interaction for customers
 */
class CustomerRepo extends BaseNoviApiRepo {    
    getCustomer(id: number) {
        return super.get<DtoCustomer>(`/novi-api/membership/get-customer?customerId=${id}`)
            .then(super.getNoviApiDataHandler<DtoCustomer>())
            .catch(super.getNoviApiErrorHandler<DtoCustomer>());
    }

    getFilteredMembersForMemberSignup(searchText: string) {
        return super.get<SelectListItemWithSubtext[]>(`/novi-api/membership/get-filtered-members?searchText=${encodeURIComponent(searchText)}`)
            .then(super.getNoviApiDataHandler<SelectListItemWithSubtext[]>())
            .catch(super.getNoviApiErrorHandler<SelectListItemWithSubtext[]>());
    }

    getAllFilteredMembers(searchText: string) {
        return super.get<SelectListItemWithSubtext[]>(`/novi-api/payments/filtered-members?searchText=${encodeURIComponent(searchText)}`)
            .then(super.getNoviApiDataHandler<SelectListItemWithSubtext[]>())
            .catch(super.getNoviApiErrorHandler<SelectListItemWithSubtext[]>());
    }

    getDefaultMembers(email: string) {
        return super.get<DefaultMemberResult>(`/novi-api/membership/get-default-members?email=${encodeURIComponent(email)}`)
            .then(super.getNoviApiDataHandler<DefaultMemberResult>())
            .catch(super.getNoviApiErrorHandler<DefaultMemberResult>());
    }

    getFieldConfiguration(forCompanies: boolean, keyInfoOnly: boolean, memberTypeId?: number | null, parentCustomerId?: number | null) {
        var url = `/novi-api/membership/get-field-config?forCompany=${forCompanies}&keyInfoOnly=${keyInfoOnly}`;
        if (memberTypeId) {
            url = url + `&memberTypeId=${memberTypeId}`;
        }
        if (parentCustomerId) {
            url = url + `&parentCustomerId=${parentCustomerId}`;
        }
        return super.get<CustomerFieldConfig>(url)
            .then(super.getNoviApiDataHandler<CustomerFieldConfig>())
            .catch(super.getNoviApiErrorHandler<CustomerFieldConfig>());
    }

    saveKeyInfo(dtoCustomer: DtoCustomer) {
        return super.post<DtoCustomer>('/novi-api/membership/save-key-info', dtoCustomer)
            .then(super.getNoviApiDataHandler<DtoCustomer>())
            .catch(super.getNoviApiErrorHandler<DtoCustomer>());
    }

    createUserAccount(dtoCustomer: DtoCustomer, dtoUserAccount: DtoUserAccount, verificationCode: string, dtoParentCustomer?: DtoCustomer, recaptchaToken?: string) {
        return super.post<DtoCustomerCreateResponse>('/novi-api/membership/create-user-account', { 
            Customer: dtoCustomer,
            UserAccount: dtoUserAccount,
            ParentCustomer: dtoParentCustomer,
            VerificationCode: verificationCode,
            RecaptchaToken: recaptchaToken
        })
            .then(super.getNoviApiDataHandler<DtoCustomerCreateResponse>())
            .catch(super.getNoviApiErrorHandler<DtoCustomerCreateResponse>());
    }

    getDuesLinesForCustomer(id: number, memberTypeId?: number, addSeatsOnly?: boolean, seatQuantity?: number | undefined | null) {
        var memberTypeQuery = memberTypeId ? `&memberTypeId=${memberTypeId}` : '';
        var addSeatsOnlyQuery = addSeatsOnly ? `&addSeatsOnly=${addSeatsOnly}` : '';
        var seatQuantityQuery = seatQuantity ? `&seatQuantity=${seatQuantity}` : '';
        return super.get<DtoDuesLineItemResponse>(`/novi-api/membership/get-dues-lines?customerId=${id}${memberTypeQuery}${addSeatsOnlyQuery}${seatQuantityQuery}`)
            .then(super.getNoviApiDataHandler<DtoDuesLineItemResponse>())
            .catch(super.getNoviApiErrorHandler<DtoDuesLineItemResponse>());
    }

    membershipCheckout(customerId: number, paymentToken: PaymentContainerToken, removedDuesRuleIds: number[], addOns: DtoProductQuantity[], addSeatsOnly: boolean, isDonatingTransactionFee: boolean, memberTypeId?: number, promoCode?: string, seatQuantity?: number | undefined | null) {
        return super.post<DtoCustomerCreateResponse>('/novi-api/membership/process-membership-checkout', 
                { 
                    customerId: customerId,
                    paymentToken: paymentToken,
                    removedDuesRuleIds: removedDuesRuleIds,
                    addOns: addOns,
                    memberTypeId: memberTypeId,
                    promoCode: promoCode,
                    seatQuantity: seatQuantity,
                    addSeatsOnly: addSeatsOnly,
                    isDonatingTransactionFee: isDonatingTransactionFee,
                })
            .then(super.getNoviApiDataHandler<DtoCustomerCreateResponse>())
            .catch(super.getNoviApiErrorHandler<DtoCustomerCreateResponse>());
    }

    getMemberTypes(memberTypeId? : number) {
        return super.get<DtoMemberType[]>(`/novi-api/membership/get-member-types` + (memberTypeId ? `?id=${memberTypeId}` : ""))
            .then(super.getNoviApiDataHandler<DtoMemberType[]>())
            .catch(super.getNoviApiErrorHandler<DtoMemberType[]>());
    }

    getSecretMemberTypes(memberTypeId?: number) {
        return super.get<DtoMemberType[]>(`/novi-api/membership/get-member-types` + (memberTypeId ? `?id=${memberTypeId}&isSecretLink=true` : ""))
            .then(super.getNoviApiDataHandler<DtoMemberType[]>())
            .catch(super.getNoviApiErrorHandler<DtoMemberType[]>());
    }

    checkForDuplicate(email: string, firstName: string | null, lastName: string | null, parentId: number | null) {
        return super.get<DuplicateCheckResult>(`/novi-api/membership/find-duplicate-email?email=${encodeURIComponent(email)}&firstName=${firstName}&lastName=${lastName}&parentID=${parentId}`)
            .then(super.getNoviApiDataHandler<DuplicateCheckResult>())
            .catch(super.getNoviApiErrorHandler<DuplicateCheckResult>());
    }

    findDuplicateCompany(name: string, billingAddress: DtoAddress | null, shippingAddress:DtoAddress | null) {
        var url = `/novi-api/membership/find-duplicate-company?name=${name}`;
        if (billingAddress && billingAddress.address1)
            url += `&billingAddress1=${billingAddress.address1}`;
        if (billingAddress && billingAddress.address2)
            url += `&billingAddress2=${billingAddress.address2}`;
        if (shippingAddress && shippingAddress.address1)
            url += `&shippingAddress1=${shippingAddress.address1}`;
        if (shippingAddress && shippingAddress.address2)
            url += `&shippingAddress2=${shippingAddress.address2}`;

        return super.get<DuplicateCheckResult>(url)
            .then(super.getNoviApiDataHandler<DuplicateCheckResult>())
            .catch(super.getNoviApiErrorHandler<DuplicateCheckResult>());
    }

    sendMemberVerificationCode(customerId: number, customerEmail: string | null) {
        if (!customerId)
            customerId = 0;
        if(!customerEmail)
            customerEmail = '';
        return super.post<{ maskedEmail: string }>(`/novi-api/membership/send-validation-code`, {
            customerId,
            email: customerEmail
        })
            .then(super.getNoviApiDataHandler<{ maskedEmail: string }>())
            .catch(super.getNoviApiErrorHandler<{ maskedEmail: string }>());
    }

    confirmVerificationCode(customerId: number, code: string) {
        if (!code)
            code = 'abc123';
        if (!customerId) {
            customerId = 0;
        }
        return super.post<DtoCustomer>(`/novi-api/membership/confirm-validation-code`, {
            validationCode: code,
            customerId
        })
            .then(super.getNoviApiDataHandler<DtoCustomer>())
            .catch(super.getNoviApiErrorHandler<DtoCustomer>());
    }

    createNewMember(dtoCustomer: DtoCustomer, dtoUserAccount: DtoUserAccount, dtoParentCustomer?: DtoCustomer, recaptchaToken?: string) {
        return super.post<DtoCustomer>('/novi-api/membership/create-new-member', {
            Customer: dtoCustomer,
            ParentCustomer: dtoParentCustomer,
            UserAccount: dtoUserAccount,
            RecaptchaToken: recaptchaToken
        })
        .then(super.getNoviApiDataHandler<DtoCustomer>())
        .catch(super.getNoviApiErrorHandler<DtoCustomer>());
    }

    validateCoupon(customerId: number, memberTypeId: number, productIds: number[], code: string) {
        return super.post<CouponValidationResult>('/novi-api/membership/validate-coupon-code', {
            customerId,
            memberTypeId,
            productIds,
            code
        })
        .then(super.getNoviApiDataHandler<CouponValidationResult>())
        .catch(super.getNoviApiErrorHandler<CouponValidationResult>());
    }

    getCurrentUserEmail() {
        return super.get<string | null>(`/novi-api/membership/get-current-user-email`)
            .then(super.getNoviApiDataHandler<string | null>())
            .catch(super.getNoviApiErrorHandler<string | null>());
    }

    getCurrentUserId() {
        return super.get<string | null>(`/novi-api/membership/get-current-user-id`)
            .then(super.getNoviApiDataHandler<string | null>())
            .catch(super.getNoviApiErrorHandler<string | null>()); 
    }

    getCurrentUser() {
        return super.get<DtoCurrentUser>(`/novi-api/membership/get-current-user`)
            .then(super.getNoviApiDataHandler<DtoCurrentUser>())
            .catch(super.getNoviApiErrorHandler<DtoCurrentUser>()); 
    }

    isPasswordCompromised(password: string) {
        return super.post<{ isPasswordCompromised: boolean; }>(`/novi-api/membership/is-password-compromised`, {
            password
        })
            .then(super.getNoviApiDataHandler<{ isPasswordCompromised: boolean }>())
            .catch(super.getNoviApiErrorHandler<{ isPasswordCompromised: boolean }>());
    }
}

export const customerRepo = new CustomerRepo();