

































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { DtoVolunteerWork, createDtoVolunteerWork } from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';

@Component({
    components: {
        FieldValidationErrors
    }
})
export default class VolunteerWork extends Vue {
    @Prop({ required: true, default: () => createDtoVolunteerWork()})
    volunteerWorks!: DtoVolunteerWork[];

    @Prop({ required: false, default: Math.random().toString(36).substr(2, 9)})
    idPrefix!: string;

    // Private variables
    volunteerWork: DtoVolunteerWork = createDtoVolunteerWork();

    get elementIds() {
        const ids = {
            volunteerWorkName: `${this.idPrefix}_volunteer-work_name`
        };
        return ids;
    }

    addVolunteerWork() {
        if (!this.volunteerWork.name && this.errors.has(this.elementIds['volunteerWorkName']) === false) {
            this.errors.add({
                field: this.elementIds['volunteerWorkName'],
                msg: 'Please provide a name for the volunteer work'
            });
        }
        else if (this.volunteerWork.name) {
            this.errors.remove(this.elementIds['volunteerWorkName']);
        }

        if (this.errors.any() === false)
        {
            this.volunteerWorks.push(this.volunteerWork);
            this.volunteerWork = createDtoVolunteerWork();
        }
    }

    deleteVolunteerWork(deletedVolunteerWork: DtoVolunteerWork) {
        var indexToDelete = -1;
        this.volunteerWorks.some(function (el: any, i: any): boolean {
            if (indexToDelete === -1 &&
                el.id == deletedVolunteerWork.id &&
                el.name == deletedVolunteerWork.name) {
                indexToDelete = i;
                return true;
            }

            return false;
        });

        this.volunteerWorks.splice(indexToDelete, 1);
    }

    create() {            
    }
}
