import { ApiErrorResponse, createApiErrorResponse } from '../ApiErrorResponse';
import { PaymentProcessingStatus } from './PaymentProcessingStatus';

export interface ApiPaymentErrorResponse extends ApiErrorResponse {
    paymentProcessingStatus: PaymentProcessingStatus;
}

export function createApiPaymentErrorResponse(obj?: ApiErrorResponse | any) {
    const result = <ApiPaymentErrorResponse>createApiErrorResponse();
    result.paymentProcessingStatus = PaymentProcessingStatus.None;

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}