import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
    name: 'PercentageRing',
    components: {
        
    }
})
export default class PercentageRing extends Vue {
    @Prop({required: true})
    percentage: number = 0;

    @Prop({required: false})
    text: string = "";

    created() {
    }

}