import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class NoviSwitch extends Vue {
    @Prop({ required: true, default: () => "" })
    label!: string;

    @Prop({ required: false, default: () => false })
    value!: boolean;

    @Emit('input')
    emitInput() {
        return this.value;
    }

    toggle() {
        this.value = !this.value;
        this.emitInput();
    }

    toggleKeyboard() {
        this.value = !this.value;
        $(this.$el).find("input:checkbox:first").prop("checked", this.value);
        this.emitInput();
    }

    /* Sets the initial value of the hidden checkbox - Using a binding seems to break things */
    mounted() {
        //@ts-ignore
        $(this.$el).find("input:checkbox:first").prop("checked", this.value);
    }
}