






















import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import { CustomField } from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import CharacterCount from '../inputs/CharacterCount.vue';

    @Component({
        components: {
            CharacterCount,
            FieldValidationErrors
        },
    })
    export default class CustomFieldWebsiteURLEditor extends Vue {
        //External
        @Prop({ required: true })
        fieldConfig!: CustomField;

        @Prop({ required: true })
        value?: string;

        @Prop({ required: true })
        id!: string;

        @Prop({ required: false, default: ''})
        validatorScope!: string;
        
        @Prop({ required: false, default: false})
        readOnly!: boolean;

        @Prop({ required: false, default: false })
        hideDisabledMessage!: boolean;

        @Prop({ required: false, default: null })
        isRequired!: boolean;

        @Inject('validator')
        $validator: any;

        @Emit('input')
        emitInput() {
            return this.value;
        }

        get rules() {
            return {
                required: this.required,
                dangerContent: true,
                url: true
            }
        }

        get required() {
            return (this.isRequired == null && this.fieldConfig.requiredFrontend && !this.readOnly) || (this.isRequired != null && this.isRequired);
        }

        get showRestrictionsMessage() {
            return this.readOnly && !this.hideDisabledMessage;
        }
    }
