import { Component, Vue, Prop, Inject, Watch } from 'vue-property-decorator';
import { Multiselect } from 'vue-multiselect';
import { customerRepo } from '@/common/repositories/CustomerRepo';
import { AxiosResponse } from 'axios';
import { ApiErrorResponse, createBasicSelectListItem, SelectListItemWithSubtext, createSelectListItemWithSubtext, BasicSelectListItem } from '@/common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import debounce from 'lodash/debounce';
import { DefaultMemberResult } from '../../common/types/membership/DefaultMemberResult';
import ariaSelectedByClassName from '@/components/directives/ariaSelectedByClassName-directive';
import ariaDescribedById from '@/components/directives/ariaDescribedById-directive';

@Component({
    components: {
        Multiselect,
        FieldValidationErrors
    },
    directives: {
        ariaSelectedByClassName,
        ariaDescribedById
    }
})
export default class CustomerSelector extends Vue {
    @Prop({ required: false, default: () => 'Parent Member' })
    entityName!: string;

    @Prop({ required: false, default: () => '' })
    customPlaceHolderText!: string;

    @Prop({ required: false, default: () => 'membership' })
    searchContext!: string;

    @Prop({ required: false, default: () => '' })
    describedById!: string;

    @Prop({ required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, default: false })
    locked!: boolean;

    @Prop({ required: false, default: false })
    required!: boolean;

    @Prop({ required: false, default: () => 'This field is required.' })
    requiredMessage!: string;

    @Prop({ required: false, default: false })
    allowAdd!: boolean;

    @Prop({ required: false, default: () => null })
    validationScope!: string;

    @Prop({ required: false, default: () => null })
    defaultEmailDomain!: string;

    @Prop({ required: false, default: () => null })
    syncCompanyName!: string;

    customers: SelectListItemWithSubtext[] = [];
    selectedCustomer: SelectListItemWithSubtext | null = null;
    debouncedOnSearch: any = debounce(this.onSearchUpdated, 300);

    // Internal
    loading = false;
    errorMessage = '';

    @Inject('validator')
    $validator: any;

    get placeHolderText() {
        return this.customPlaceHolderText || 'Begin typing the name of your ' + this.entityName;
    }

    get requireCustomer() {
        return this.required && !this.disabled;
    }

    get hasCustomer() {
        return this.selectedCustomer !== null && this.selectedCustomer.text > "";
    }    

    get totalResultsMessage() {
        return this.customers.length + " results returned.";
    }

    validateCustomerRequired() {
        var hasError = this.hasError();
                
        if (this.requireCustomer && !this.hasCustomer && !hasError) {
            this.addError();
        }
        else if (this.requireCustomer && this.hasCustomer && hasError) {
            this.removeError();
        }
        else if (!this.requireCustomer && hasError) {
            this.removeError();
        }
    }

    hasError() {
        return this.$validator.errors && this.$validator.errors.collect('customerSelector', this.validationScope).length > 0;
    }

    addError() {
        this.$validator.errors.add({
            field: 'customerSelector',
            msg: this.requiredMessage,
            scope: this.validationScope
        });
    }

    removeError() {
        this.$validator.errors.remove('customerSelector', this.validationScope);
    }

    @Watch('disabled', { immediate: true })
    onDisabledChanged(newDisabledVal: boolean) {
        this.disabled = newDisabledVal;
        if (this.disabled) {
            this.onCustomerUpdated(null);
        }
    }
    
    onSearchUpdated(searchText: string) {
        if (searchText.length > 0) {
            this.loading = true;

        var searchPromise : Promise<SelectListItemWithSubtext[]>;

        if (this.searchContext == 'membership') {
            searchPromise = customerRepo.getFilteredMembersForMemberSignup(searchText);
        }
        else {
            searchPromise = customerRepo.getAllFilteredMembers(searchText);
        }

                searchPromise.then((filteredMembers: SelectListItemWithSubtext[]) => {
                    this.loading = false;
                    this.customers.splice(0, this.customers.length, ...filteredMembers);
                    this.errorMessage = '';
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.loading = false;
                    this.errorMessage = axiosResponse.data.message;
                });
        }
    }

    setSelectedCustomer(customer: BasicSelectListItem) {
        if (!customer) {
            this.selectedCustomer = null;
        }
        else {
            this.selectedCustomer = createSelectListItemWithSubtext({
                id: customer.id,
                text: customer.displayName,
                taxable: customer.taxable
            });
        }

        this.validateCustomerRequired();
    }

    onCustomerUpdated(newValue: SelectListItemWithSubtext | null) {
        var updatedCustomer = createBasicSelectListItem({
            id: newValue ? newValue.id : null,
            displayName: newValue ? newValue.text : '',
            taxable: newValue ? newValue.taxable : true
        });
        
        this.selectedCustomer = newValue;
        this.validateCustomerRequired();

        this.$emit("update:customer", updatedCustomer);
    }

    onCustomerAdded(customerName: string) {
        var addedCustomer = createSelectListItemWithSubtext({ text: customerName });
        this.customers.unshift(addedCustomer);
        this.onCustomerUpdated(addedCustomer);
    }

    onCustomerOpen() {
        if (this.selectedCustomer) {
            this.customers = [this.selectedCustomer];
        }
    }

    beforeDestroy() {
        if (this.hasError()) {
            this.removeError();
        }
    }

    mounted() {
        if (!this.defaultEmailDomain) {
            return;
        }

        var searchPromise: Promise<DefaultMemberResult>;

        searchPromise = customerRepo.getDefaultMembers(this.defaultEmailDomain);

        searchPromise.then((result: DefaultMemberResult) => {
            this.loading = false;
            this.customers = result.customers;

            if (this.selectedCustomer == null && result.selectedCustomer != null) {
                this.selectedCustomer = result.selectedCustomer;
            }
            
            this.onCustomerUpdated(this.selectedCustomer);
            this.errorMessage = '';
        })
        .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
           this.loading = false;
           this.errorMessage = axiosResponse.data.message;
        });

        var self = this;
        this.$root.$on('update-new-item-name', function (newName: string) {
            if (self.selectedCustomer != null && self.selectedCustomer.id == 0) {
                self.selectedCustomer.text = newName;
            } 
        });
    }
}