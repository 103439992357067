import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import { PropOptions } from 'vue';
import FieldValidationErrors from '@/components/errors/FieldValidationErrors.vue';

@Component({
    name: 'Donation',
    components: {
        FieldValidationErrors
    }
})
export default class DonationInput extends Vue {
    @Prop({ required: true, default: 0 } as PropOptions<number>)
    value!: number;

    @Prop({ required: true, default: '' } as PropOptions<string>)
    id!: string;

    @Prop({ required: false, default: '' } as PropOptions<string>)
    name!: string;

    @Prop({ required: false, default: 0 } as PropOptions<number>)
    minimumPrice!: number;

    @Inject('validator')
    $validator: any;

    isInputActive: boolean = false;
    internalValue: string = this.value.toString();
    showSuggestedDonationText: boolean = this.internalValue.length > 0 && this.internalValue !== "0";

    get displayValue(): string {
        // Blank input is fine - users might want to blank out the control and then type the price - we'll treat that as a zero
        if (!this.internalValue) {
            this.emitInput();
            return "";
        }

        // if we are active in the editor and so far we don't detect any illegal characters than just return current internalValue
        // We do this to avoid cursor posiiton changes that can occur when cleaning the internalValue.
        if (this.isInputActive
            && this.internalValue == this.cleanInput(this.internalValue)) {
            return this.internalValue;
        }

        return this.cleanInput(Number(this.internalValue).toFixed(2));
    }

    set displayValue(modifiedValue: string) {
        if (!modifiedValue) {
            this.internalValue = "";
            return;
        }
        let newValue = parseFloat(this.cleanInput(modifiedValue))
        if (isNaN(newValue) || !isFinite(newValue)) {
            newValue = 0
        }

        if (newValue < 0) {
            newValue = 0;
        }

        if (!this.isInputActive && newValue.toString().includes(".")) {
            this.internalValue = newValue.toFixed(2);
        } else {
            this.internalValue = newValue.toString();
        }

        this.emitInput();
    }

    isValidInput(event: KeyboardEvent) {
        let keyCode = (event.keyCode ? event.keyCode : event.which);
        if ((keyCode < 46 || keyCode > 57) && (keyCode !== 46)) { // 46 is dot
            event.preventDefault();
        }
    }

    onBlur() {
        this.isInputActive = false;
        if (this.internalValue) {
            this.internalValue = Number(this.internalValue).toFixed(2);
        }
    }

    onFocus() {
        this.isInputActive = true;
    }

    @Emit('input')
    emitInput() {
        return Number(this.internalValue) || 0; // Treat empty as zero
    }

    // Removes non-digits and non-dots from content
    cleanInput(input: string) {
        return input.replace(/[^\d\.]/g, "");
    }
}