























import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import * as NoviTypes from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { Multiselect } from 'vue-multiselect';

@Component({
    components: {
        Multiselect,
        FieldValidationErrors
  },
})
export default class Credentials extends Vue {
    //External
    @Prop({required: true})
    required!: Boolean;

    @Prop({required: true})
    value?: String;
    
    @Inject('validator')
    $validator: any;

    //Internal
    selectedValues: String[] = this.splitBySemicolon(this.value);

    //Functions
    created() {
        this.emitInput();
    }

    @Emit('input')
    emitInput(data?: string | null) {
        if (data) {
            this.selectedValues.push(data);
        }
        return this.selectedValues.join(";");
    }

    addCredential(newTag: String) {
        this.value = this.value + ";" + newTag;
        this.selectedValues.push(newTag);
        this.emitInput();
    }

    splitBySemicolon(myString?: String) {
        return myString ? myString.split(";") : [];
    }
    
    get setOfOptions() {
        this.selectedValues = this.splitBySemicolon(this.value);
        return this.selectedValues;
    }
}
