export interface CouponValidationResult {
    discountType: number;
    discountMethod: number;
    discountAmount: number;
    discountPercent: number;
    discountDuesRules: number[];
    discountTickets: number[];
    discountProducts: number[];
    availableUses: number | null;
    error: string;
}

export function createCouponValidationResult(obj?: CouponValidationResult) {
    const result = <CouponValidationResult>{
        discountType: 0,
        discountMethod: 0,
        discountAmount: 0,
        discountPercent: 0,
        discountDuesRules: [],
        discountTickets: [],
        discountProducts: [],
        validForEvents: false,
        validForProducts: false,
        availableUses: null,
        error: ''
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}