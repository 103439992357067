import { Component, Vue, Prop, Inject, Watch } from 'vue-property-decorator';
import { CreditCardTokenData, createCreditCardTokenData, PaymentProcessorEnum, GoogleAddressComponentNameMap, createGoogleAddressComponentNameMap, GoogleAddressComponentValueMap, createGoogleAddressComponentValueMap } from '@/common/types/AllTypesExports';
import StripeCardInput from './StripeCardInput.vue';
import QuickBooksPaymentsCardInput from './QuickBooksPaymentsCardInput.vue';
import { default as StripeCardInputClass } from './StripeCardInputVue';
import { default as QuickBooksPaymentsCardInputClass } from './QuickBooksPaymentsCardInputVue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';

declare let noviNs: INoviNsPaymentGlobals;

@Component({
    name: 'CreditCardEntryForm',
    components: {
        StripeCardInput,
        QuickBooksPaymentsCardInput,
        FieldValidationErrors
    }
})
export default class CreditCardEntryForm extends Vue {
    @Prop({ required: false, default: () => false })
    saveCardOption!: boolean;

    @Prop({ required: false, default: () => false })
    forceSaveCard!: boolean;

    @Prop({ required: false, default: () => true })
    validate!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    creditCardData: CreditCardTokenData = createCreditCardTokenData();

    addressAutocomplete!: google.maps.places.Autocomplete;
    addressAutocompleteListener!: google.maps.MapsEventListener;
    mapsIsInitialized = false;

    // This object allows us to map the incoming google result keys
    addressComponentNamesMap: GoogleAddressComponentNameMap = createGoogleAddressComponentNameMap();

    // This object allows us to map the incoming google result values
    addressComponentValues: GoogleAddressComponentValueMap = createGoogleAddressComponentValueMap();

    @Inject('validator')
    $validator: any;

    @Watch('forceSaveCard', { immediate: true })
    onForceSaveCard(forceSave: boolean) {
        if (this.saveCardOption && forceSave)
            this.creditCardData.saveCard = true;
    }

    @Watch('creditCardData.billingState')
    onBillingStateChange(billingState: string) {
        this.$emit('update:credit-card-billing-state', billingState);
    }

    get isPaymentProcessorStripe() {
        return noviNs.paymentProcessor == PaymentProcessorEnum.stripe;
    }

    get isPaymentProcessorQuickBookPayments() {
        return noviNs.paymentProcessor == PaymentProcessorEnum.quickBooksPayments;
    }

    get showCountry() {
        return noviNs.alternatePaymentStripeId != null && noviNs.alternatePaymentStripeId != "";
    }

    onCreditCardPaymentTokenReady(token: CreditCardTokenData) {
        Object.assign(this.creditCardData, token);
        this.$emit('on-payment-token-ready', token);
    }


    onError(errorMsg: string) {
        this.emitError(errorMsg);
    }

    emitError(errorMsg: string) {
        this.$emit('on-error-toggle', true, errorMsg);
    }

    setupGoogleAutocomplete() {
        if (typeof google === 'undefined') {
            return;
        }

        const elemId = "billingStreet";
        const addressElem = document.getElementById(elemId);

        if (!addressElem) {
            return;
        }

        addressElem.addEventListener("focus", function () {
            addressElem.setAttribute("autocomplete", "nope");
        });

        this.addressAutocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>addressElem, { types: ["geocode"] });
        this.addressAutocompleteListener = this.addressAutocomplete.addListener("place_changed", () => {
            // Get the current selected place
            const place = this.addressAutocomplete.getPlace();

            //Extract the values out of the result and map it into the local addressComponentValues object
            if (!place || !place.address_components) {
                return;
            }

            place.address_components.forEach(addressComponent => {
                // This maps the component type (i.e. the 'city', 'state', 'zip', etc.)
                const addressPart = addressComponent.types[0];
                if (this.addressComponentNamesMap[addressPart]) {
                    // Now we have a component type mapped over, we set the value (i.e. the street address, city, state, zip, etc.)
                    // We have to type cast the addressComponent because the GeocoderAddressComponent type does not have the appropriate signature 
                    this.addressComponentValues[addressPart] = (<{ [key: string]: any }>addressComponent)[this.addressComponentNamesMap[addressPart]];
                }
            });

            // Set the values gathered from google maps to the local values
            this.creditCardData.billingStreet = `${this.addressComponentValues.street_number} ${this.addressComponentValues.route}`;
            this.creditCardData.billingCity = `${this.addressComponentValues.locality}` || `${this.addressComponentValues.postal_town}`;
            this.creditCardData.billingState = `${this.addressComponentValues.administrative_area_level_1}`;
            this.creditCardData.billingZip = `${this.addressComponentValues.postal_code}` || `${this.addressComponentValues.postal_code_prefix}`;
            this.creditCardData.billingCountry = `${this.addressComponentValues.country}`;
        });

        this.mapsIsInitialized = true;
    }

    createPaymentToken(autoPay: boolean, billablePartyId: number) {
        this.creditCardData.autoPay = autoPay;
        this.creditCardData.billablePartyId = billablePartyId;
        if (this.isPaymentProcessorStripe) {
            let creditCardContainer = <StripeCardInputClass>this.$refs.stripeCardInput;
            creditCardContainer.createStripePaymentToken(this.creditCardData);
        } else if (this.isPaymentProcessorQuickBookPayments) {
            let creditCardContainer = <QuickBooksPaymentsCardInputClass>this.$refs.quickBooksPaymentsCardInput;
            creditCardContainer.createQbPaymentToken(this.creditCardData);
        } else {
            this.emitError('Could not submit payment: No payment processor set up.');
        }
    }

    mounted() {
        const self = this;
        this.setupGoogleAutocomplete();

        // This is here as a fallback mechanism in case the google maps sdk has not been loaded when mounted() is executed
        window.addEventListener('load', () => {
            if (!this.mapsIsInitialized) {
                self.setupGoogleAutocomplete();
            }
        });
    }
}