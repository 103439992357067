export interface DtoEvent {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
    allDayEvent: boolean | null;
    location: string;
    attendeeBased: boolean | null;
    timeZone: string;
    oneDayEvent: boolean | null;
    alreadyAttending: boolean;
    attendeeInstructions: string | null;
    ticketsInstructions: string | null;
    invoiceFee: number;
    taxRate: number;
    eventPolicyPageName: string | null;
    eventPolicyUsage: string | null;
    hasPromos: boolean;
    purchaserHasManagementAccess: boolean;
    fileName: string;
    fileNameWithPrefix: string;
    requireUniqueEmailPerAttendee: boolean;
}

export function createDtoEvent(dtoEvent?: DtoEvent | any) {
    let obj = <DtoEvent>{
        name: '',
        allDayEvent: false,
        location: '',
        attendeeBased: false,
        timeZone: '',
        oneDayEvent: false,
        alreadyAttending: false,
        attendeeInstructions: '',
        ticketsInstructions: '',
        invoiceFee: 0,
        taxRate: 0,
        eventPolicyPageName: '',
        eventPolicyUsage: '',
        hasPromos: false,
        purchaserHasManagementAccess: false,
        fileName: '',
        fileNameWithPrefix: '',
        requireUniqueEmailPerAttendee: false
    }; 

    if (dtoEvent) {
        Object.assign(obj, dtoEvent);
    }

    return obj;
}