import { Component, Vue, Prop, Inject, Watch, Emit } from 'vue-property-decorator';
import AchEntryForm from './AchEntryForm.vue';
import WarningAlert from '../../components/errors/WarningAlert.vue';
import { default as AchEntryFormClass } from './AchEntryFormVue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { PropOptions } from 'vue';
import { createPaymentProcessorOptions, PaymentProcessorOptions, PaymentContainerToken, createPaymentContainerToken, InvoiceTokenData, CreditCardTokenData, PaymentTypeEnum, AchTokenData, createAchTokenData, BasicSelectListItem, createBasicSelectListItem, ApiErrorResponse } from '@/common/types/AllTypesExports';
import { paymentsRepo } from '../../common/repositories/PaymentsRepo';
import { AxiosResponse } from 'axios';
import CustomerSelector from '../membership/CustomerSelector.vue';

@Component({
    name: 'achContainer',
    components: {
        AchEntryForm,
        FieldValidationErrors,
        WarningAlert,
        CustomerSelector
    }
})
export default class AchContainerClass extends Vue {

    @Prop({ required: true } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: false, default: () => true })
    achActive!: boolean;

    @Prop({ required: false, default: () => false })
    forceSaveAch!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Prop({ required: false, default: () => "" })
    achRestrictionMessage!: string;

    storedBankAccountId: string = "";
    newAchSelected: boolean = false;
    autoPay: boolean = false;
    billableParties: BasicSelectListItem[] = [];
    billableParty = createBasicSelectListItem();
    errorMessage: string = '';
    localWarningMessage: string = '';
    isCheckingForBillableParties: boolean = false;
    noBillablePartiesMessage: string = 'No eligible billable parties found for ACH. If you believe this to be an error, please contact us.';

    achTokenData = createAchTokenData(<AchTokenData>{
        storedBankAccountId: this.storedBankAccountId,
        autoPay: this.autoPay,
        last4Digits: this.storedBankAccountId.substr(-4),
        billablePartyId: this.processorOptions.billablePartyId
    });

    @Inject('validator')
    $validator: any;

    truncateName(name: string) {
        return (name.length > 30) ? name.substr(0, 29) + '...' : name;
    }

    get preventAchUsageMessage() {
        return this.localWarningMessage || '';
    }

    get preventAchUsage() {
        return !!this.preventAchUsageMessage.length;
    }

    get showSavedAchs() {
        if (this.processorOptions && this.processorOptions.savedAchs) {
            this.storedBankAccountId = this.processorOptions.savedAchs.length ? this.processorOptions.savedAchs[0].id : '';
            return this.processorOptions.enableSaveAch && this.processorOptions.savedAchs.length > 0;
        }
        return false;
    }

    expandNewAchEntry() {
        if (!this.locked) {
            this.storedBankAccountId = "";
            this.newAchSelected = true;
        }
    }

    selectStoredAch(selectedBankAccountId: string) {
        if (!this.locked) {
            this.newAchSelected = false;
            this.storedBankAccountId = selectedBankAccountId;
        }
    }

    @Emit('ach-token-updated')
    emitAchTokenUpdate() {
        return this.achTokenData;
    }

    @Watch('billableParty')
    onBillablePartyChange(newBillableParty: BasicSelectListItem) {
        Object.assign(this.billableParty, newBillableParty);
        this.achTokenData.billablePartyId = this.billableParty.id;
        this.emitAchTokenUpdate();
    }

    // Watch for external changes to billablePartyId (change purchaser)
    @Watch('processorOptions.billablePartyId')
    onBillablePartyIdChange(newBillablePartyId: number) {
        if (isNaN(newBillablePartyId)) {
            this.updateBillToDropdownSelector(null);
        }
        else if (newBillablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('SalesReceipt', newBillablePartyId)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';
                    this.localWarningMessage = '';

                    if (this.billableParties.length) {
                        const newBillableParty = this.billableParties.find(b => b.id === newBillablePartyId) ?? this.billableParties[0];
                        Object.assign(this.billableParty, newBillableParty);
                        this.onBillablePartyChange(this.billableParty);
                        this.updateBillToDropdownSelector(this.billableParty);
                    }
                    else {
                        this.localWarningMessage = this.noBillablePartiesMessage;
                    }

                    this.onIsAchProcessingPrevented(this.preventAchUsage);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }
    }

    onIsAchProcessingPrevented(value: boolean) {
        this.$emit('on-is-ach-processing-prevented', value);
    }

    onAchPaymentTokenReady(card: AchTokenData) {
        this.$emit('on-ach-token-ready', card);
    }

    onErrorToggle(hasError: boolean, errorMsg: string) {
        this.$emit('on-error-toggle', hasError, errorMsg);
    }

    createPaymentToken() {
        if (this.storedBankAccountId) {
            var savedAchToken = createAchTokenData(<AchTokenData>{
                storedBankAccountId: this.storedBankAccountId,
                autoPay: this.autoPay,
                last4Digits: this.storedBankAccountId.substr(-4),
                billablePartyId: this.billableParty.id
            });
            this.onAchPaymentTokenReady(savedAchToken);
        }
        else {
            const achEntryContainer = <AchEntryFormClass>this.$refs.achEntryForm;
            achEntryContainer.createPaymentToken(this.autoPay, this.billableParty.id);
        }
    }

    mounted() {
        if (this.achTokenData.billablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('SalesReceipt', this.processorOptions.customerForBillableParties ?? this.achTokenData.billablePartyId)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';
                    this.localWarningMessage = '';

                    if (this.billableParties.length) {
                        const currentBillableParty = this.billableParties.find(b => b.isDefault) ?? this.billableParties[0];
                        Object.assign(this.billableParty, currentBillableParty);
                        this.onBillablePartyChange(this.billableParty);
                        this.updateBillToDropdownSelector(this.billableParty);
                    }
                    else {
                        this.localWarningMessage = this.noBillablePartiesMessage;
                    }

                    this.onIsAchProcessingPrevented(this.preventAchUsage);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }
    }

    // Update the 'Bill To' drop down value if changed from outside the vue app
    updateBillToDropdownSelector(value: any) {
        const customerSelector = this.$refs.billablePartySelector as any;
        if (customerSelector && typeof customerSelector.setSelectedCustomer === 'function') {
            customerSelector.setSelectedCustomer(value);
        }
    }

    onGuestDataUpdated(name: string, guestName: boolean) {
        const index = guestName ? this.billableParties.findIndex(item => item.id == -1) : this.billableParties.findIndex(item => item.id == -2);

        if (index === -1) {
            if (guestName) {
                this.billableParties.push({
                    id: -1,
                    displayName: name,
                    taxable: true,
                    isDefault: false,
                });
            }
            else {
                this.billableParties.push({
                    id: -2,
                    displayName: name,
                    taxable: true,
                    isDefault: false,
                });
            }
        } else {
            this.billableParties[index].displayName = name;
        }
        if (this.billableParties.length === 1) {
            const newBillableParty = this.billableParties[0];
            Object.assign(this.billableParty, newBillableParty);
            this.onBillablePartyChange(this.billableParty);
            this.updateBillToDropdownSelector(this.billableParty);
        }
    }
}