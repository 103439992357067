export interface DtoDegree {
    id: number;
    school: string;
    year: string;
    name: string;
}

export function createDtoDegree(dtoDegree?: DtoDegree | any) {
    const result = <DtoDegree>{
        id: 0,
        name: '',
        school: '',
        year: ''
    };

    if (dtoDegree) {
        Object.assign(result, dtoDegree);
    }
    return result;
}