import { Component, Vue, Prop, Emit, Watch, Inject } from 'vue-property-decorator';
import regexPattern from '@/components/directives/regexPattern-directive';
import FieldValidationErrors from '@/components/errors/FieldValidationErrors.vue';

@Component({
    name: 'Quantity',
    components: {
        FieldValidationErrors
    },
    directives: {
        regexPattern
    }
})
export default class QuantityInput extends Vue {

    @Prop({ required: true })
    value!: number;

    @Prop({ required: true })
    id!: string;

    @Prop({ required: false, default: 0 })
    min!: number;

    @Prop({ required: false, default: 999 })
    max!: number;

    @Prop({ required: true, default: '' })
    maxMessage!: string;

    @Inject('validator')
    $validator: any;

    internalValue: number = this.value;

    minusClick() {
        if (this.internalValue > this.min) {
            this.internalValue--;
        }
    }

    plusClick() {
        if (this.internalValue > this.max) {
            return;
        }
        this.internalValue++;
    }

    @Watch('internalValue')
    valueUpdated() {
        // Blank input is fine - users might want to blank out the control and then type the quantity - we'll treat that as a zero
        if (!this.internalValue) {
            this.emitInput();
            return;
        }

        if (this.internalValue < this.min) {
            this.internalValue = this.min;
        }
        else if (this.internalValue > this.max) {
            this.internalValue = this.max;
        }

        // Convert user's manual text input back to a number
        if (typeof this.internalValue === 'string') {
            this.internalValue = parseInt(this.internalValue);
        }

        // This should catch drag n drops of non-numeric values
        if (isNaN(this.internalValue) || !isFinite(this.internalValue)) {
            this.internalValue = 0;
        }
        else {
            this.emitInput();
        }
    }

    @Emit('input')
    emitInput() {
        return this.internalValue || 0; // Treat empty as zero
    }
}