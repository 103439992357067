export interface DtoVolunteerWork {
    id: number;
    name: string;
    personId: number;
}

export function createDtoVolunteerWork(obj?: DtoVolunteerWork | any) {
    const result = <DtoVolunteerWork>{
        id: 0,
        name: '',
        personId: 0
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}