export interface DtoAward {
    id: number;
    name: string;
    year: string;
}


export function createDtoAward(dtoAward?: DtoAward | any) {
    const result = <DtoAward>{
        id: 0,
        name: '',
        year: ''
    };
    if (dtoAward) {
        Object.assign(result, dtoAward);
    }
    return result;
}