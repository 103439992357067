





















import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import * as NoviTypes from '../../common/types/AllTypesExports';

@Component({
    components: {
        FieldValidationErrors
    }
})
export default class CustomFieldNumberEditor extends Vue {
    //External
    @Prop({required: true})
    fieldConfig!: NoviTypes.CustomField;

    @Prop({required: true})
    value?: string;

    @Prop({required: true})
    id!: string;

    @Prop({ required: false, default: ''})
    validatorScope!: string;

    @Prop({ required: false, default: false})
    readOnly!: boolean;

    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;

    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    @Emit('input')
    emitInput() {
        return this.value;
    }

    get rules() {
        var myRules: any = {
            required: this.required,
            integer: this.fieldConfig.wholeNumbers,
            numeric: true
        };

        if (this.fieldConfig.minNumber) {
            myRules.min_value = this.fieldConfig.minNumber;
        }

        if (this.fieldConfig.maxNumber) {
            myRules.max_value = this.fieldConfig.maxNumber;
        }

        return myRules;
    }

    get required() {
        return (this.isRequired == null && this.fieldConfig.requiredFrontend && !this.readOnly) || (this.isRequired != null && this.isRequired);
    }

    get showRestrictionsMessage() {
        return this.readOnly && !this.hideDisabledMessage;
    }

    onKeyDown(e: any) {
        var key = e.charCode || e.keyCode || 0;
        var ctrlDown = e.ctrlKey || e.metaKey;
        var validKey = key == 8 || key == 9 || key == 13 || key == 46 || (key >= 37 && key <= 40) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key == 109 || key == 110 || key == 189 || key == 190 || (ctrlDown && (key == 86 || key == 67));
        if (!validKey) {
            e.preventDefault();
        }

        return validKey;
    }

    isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    created() {        
        this.$validator.extend('numeric', {
            getMessage: (field: string) => 'The ' + field + ' field must be numeric.',
            validate: (val: any) => this.isNumeric(val)
        });
    }
}
