import { PhoneFormatOptions, createPhoneFormatOptions } from '../types/AllTypesExports';
import { valid } from 'semver';

class StringHelperMethods {

    constructor() {
        if (!String.prototype.endsWith) {
            String.prototype.endsWith = function (search: any, this_len: any) {
                if (this_len === undefined || this_len > this.length) {
                    this_len = this.length;
                }
                return this.substring(this_len - search.length, this_len) === search;
            };
        }
        if (!String.prototype.includes) {
            String.prototype.includes = function(search, start) {
              'use strict';
              if (typeof start !== 'number') {
                start = 0;
              }
          
              if (start + search.length > this.length) {
                return false;
              } else {
                return this.indexOf(search, start) !== -1;
              }
            };
          }
    }


    /**
     * Checks to see if the string is null, undefined, empty, or contains only white-space values
     * @param $string
     */
    isNullOrWhiteSpace($string?: string | null): boolean {
        if (!$string || $string.length < 1) {
            return true;
        }

        const splitString = $string.split("");
        const isWhiteSpace = splitString.every(str => str === " ");
        return isWhiteSpace;
    }

    contains(valueForContains?: string, stringInstance?: any, isCaseSensitive: boolean = false): boolean {
        try {
            // If we have no arguments then we have an invalid call so just return false
            if (!valueForContains && !stringInstance && !isCaseSensitive) {
                return false;
            }

            let localStringInstance: string;

            if (typeof stringInstance === "boolean") {
                return false;
            }

            if (typeof stringInstance === "undefined" || stringInstance === null) {
                return false;
            }
            else if (typeof stringInstance !== "string") {
                return false;
            }
            else {
                localStringInstance = stringInstance.toString();
            }
            if (isCaseSensitive) {
                return !(typeof valueForContains === "undefined" || valueForContains === null) && localStringInstance.indexOf(valueForContains) > -1;
            }
            return !(typeof valueForContains === "undefined" || valueForContains === null) && localStringInstance.toLowerCase().indexOf(valueForContains.toLowerCase()) > -1;
        }
        catch (e) {
            console.log("Error on string.contain", e);
            return false;
        }
    }

    /**
     * Parses a string into the correct number. This takes into account whether or not it's a float or int based on if 
     * the string contains a decimal.
     * @param $string
     */
    parseNumber($string: string): number {
        if(!$string)
            return 0;
        const ignoreChars = this.contains($string, '.') ? ['.'] : [];
        const digitsString = this.getDigitsString($string, ...ignoreChars)
        if(!digitsString)
            return 0;
        const parsedNumber = this.contains($string, '.') ? parseFloat(digitsString) : parseInt(digitsString);
        return parsedNumber;
    }

    /**
     * Extracts all the numerical values of the current string into one contiguous number string
     * @param $string
     * @param includeChars Params of characters to include in the output result string.
     */
    getDigitsString($string: string, ...includeChars: string[]): string {
        if (!$string) {
            return "";
        }

        const digitsArray: string[] = this.getDigitsArray($string, ...includeChars);
        return digitsArray.length > 0 ? digitsArray.join('') : '';
    }

    /**
     * Extracts all the numerical values of a string (and any ignored characters) into one array
     * @param val
     * @param includeChars Params of characters to include in the result
     */
    getDigitsArray(val: string, ...includeChars: string[]): string[] {
        const digits: string[] = [];
        if (!val)
            return digits;

        for (let i = 0; i < val.length; i++) {
            const currentVal = val.charAt(i);
            if (!isNaN(parseInt(currentVal))) {
                digits.push(currentVal);
            }
            else if (includeChars.length > 0 && includeChars.some((v) => v === currentVal)) {
                digits.push(currentVal);
            }
        }
        return digits;
    }

    /**
     * Converts the first letter of each word to a capital letter
     * @param $string
     */
    toTitleCase($string: string): string {
        if (typeof $string === "undefined" || $string === null) {
            return "";
        }
        return $string.replace(/([^\W_]+[^\s-]*) */g, txt => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()));
    }

    /**
     * Replaces all spaces with dashes (typically used when making something URL safe).
     * @param $string
     */
    replaceSpacesWithDashes($string: string): string {
        if (typeof $string === 'undefined' || $string === null) {
            return "";
        }
        const result = $string.split(' ').join('-');
        return result;
    }

    /**
     * Replaces all dashes with spaces (typically used when converting a URL string to a "normal" string).
     * @param $string
     */
    replaceDashesWithSpaces($string: string): string {
        if (typeof $string === "undefined" || $string === null) {
            return "";
        }
        const result = $string.split('-').join(' ');
        return result;
    }

    /**
     * Removes all spaces and dashes from a string and collapses characters around them. For example, the string
     * "hello-world program" becomes "helloworldprogram".
     * @param $string
     */
    removeDashesAndSpaces($string: string): string {
        if (typeof $string === "undefined" || $string === null) {
            return "";
        }
        const splitDashes = $string.split('-').join('');
        const splitSpaces = splitDashes.split(' ').join('');
        return splitSpaces;
    }

    /**
     * Removes the given character from the beginning and end of the string.
     * @param $string The String to trim
     * @param charToRemove The character to remove
     */
    trimChar($string: string, charToRemove: string): string {
        if(!$string || !charToRemove){
            return $string ? $string : '';
        }
        if(charToRemove.length > 1){
            throw new Error(`The value of the charToRemove parameter (${charToRemove}) was more than one character`);
        }
        const result: string[] = $string.split('');
        while(result[0] === charToRemove){
            result.shift();
        }

        const length = () => {return result.length;};
        while(result[length()] === charToRemove){
            result.pop();
        }
        return result.join('');
    }

    /**
     * Removes the given character from the beginning (or left side) of the given $string
     * @param $string The string to trim
     * @param charToRemove The character to remove from the left side of the string
     */
    trimLeftChar($string: string, charToRemove: string): string {
        if(!$string || !charToRemove){
            return $string ? $string : '';
        }
        if(charToRemove.length > 1){
            throw new Error(`The value of the charToRemove parameter (${charToRemove}) was more than one character`);
        }
        const result: string[] = $string.split('');
        while(result[0] === charToRemove){
            result.shift();
        }
        return result.join('');
    }

    /**
     * Removes the given character from the end (or left side) of the given $string
     * @param $string The string to trimg
     * @param charToRemove The character to remove from the right side of the string
     */
    trimRightChar($string: string, charToRemove: string): string {
        if(!$string || !charToRemove){
            return $string ? $string : '';
        }
        if(charToRemove.length > 1){
            throw new Error(`The value of the charToRemove parameter (${charToRemove}) was more than one character`);
        }
        const result = $string.split('');
        const length = () => {return result.length;};
        while(result[length()] === charToRemove){
            result.pop();
        }
        return result.join('');
    }

    /**
     * Formats a given string into a phone number in the manner defined by the PhoneFormatOptions parameter
     * @param tel
     * @param options Options for the formatting. Defaults are -- allowExtensions: false, allowInternational: false, delimiterText: '-', extensionPrefix: 'x', includeParens: true
     */
    formatPhone(tel: string, options: PhoneFormatOptions = createPhoneFormatOptions()): string {
        if (!tel) {
            return '';
        }

        var strValue = this.getDigitsString(tel, options.extensionPrefix);
        if(!strValue)
            return '';

        if (options.allowInternational) {
            if (tel.trim().startsWith('+')) {
                return `+${strValue.substr(0, options.maxCharacters - 1)}`;
            }
            return strValue.substr(0, options.maxCharacters);
        }

        // Ensure that section prior to the extensionPrefix does not contain the extensionPrefix
        strValue = strValue.substr(0, 10).replace(options.extensionPrefix, '') + strValue.substr(10);

        // Ensure that any extension present does not contain the extensionPrefix.
        strValue = strValue.substr(0, 11) + strValue.substr(11, 9).replace(options.extensionPrefix, '');

        let areaCode: string;
        let prefix: string;
        let lineNumber: string;
        let extension: string;
        let returnString: string;

        if (strValue.length < 3 && options.includeParens) {
            returnString = `(${strValue.substr(0)}`;
        }
        else if (strValue.length === 3 && tel.endsWith(')')) {
            returnString = `(${strValue.substr(0)}`;
        }
        else if (strValue.length === 3) {
            areaCode = strValue.substr(0);
            returnString = options.includeParens ? `(${areaCode})` : `${areaCode}`;
        }
        else if (strValue.length > 3 && strValue.length < 7) {
            areaCode = strValue.substr(0, 3);
            prefix = strValue.substr(3);
            returnString = options.includeParens ? `(${areaCode})${prefix}` : `${areaCode}${options.delimiterText}${prefix}`;
        }
        else if (strValue.length > 6 && strValue.length < 11) {
            areaCode = strValue.substr(0, 3);
            prefix = strValue.substr(3, 3);
            lineNumber = strValue.substr(6, 4);
            returnString = options.includeParens ? `(${areaCode})${prefix}${options.delimiterText}${lineNumber}` : `${areaCode}${options.delimiterText}${prefix}${options.delimiterText}${lineNumber}`;
        }
        else if (strValue.length > 10 && !options.allowExtensions) {
            areaCode = strValue.substr(0, 3);
            prefix = strValue.substr(3, 3);
            lineNumber = strValue.substr(6, 4);
            returnString = options.includeParens ? `(${areaCode})${prefix}${options.delimiterText}${lineNumber}` : `${areaCode}${options.delimiterText}${prefix}${options.delimiterText}${lineNumber}`;
        }
        else if (strValue.length > 10 && strValue.length < 21) {
            areaCode = strValue.substr(0, 3);
            prefix = strValue.substr(3, 3);
            lineNumber = strValue.substr(6, 4);
            extension = strValue.substr(strValue.charAt(10) === options.extensionPrefix ? 11 : 10);
            returnString = `${options.includeParens ? "(" + areaCode + ")" : areaCode}${options.includeParens ? '' : options.delimiterText}${prefix}${options.delimiterText}${lineNumber} ${(options.allowExtensions ? options.extensionPrefix : '')} ${(options.allowExtensions ? extension : '')}`;
        }
        else if (strValue.length > 20) {
            areaCode = strValue.substr(0, 3);
            prefix = strValue.substr(3, 3);
            lineNumber = strValue.substr(6, 4);
            extension = strValue.substr(strValue.charAt(10) === options.extensionPrefix ? 11 : 10, 9);
            returnString = `${options.includeParens ? "(" + areaCode + ")" : areaCode}${options.includeParens ? '' : options.delimiterText}${prefix}${options.delimiterText}${lineNumber} ${(options.allowExtensions ? options.extensionPrefix : '')} ${(options.allowExtensions ? extension : '')}`;
        }
        else {
            returnString = strValue;
        }
        return returnString.trim().substr(0, options.maxCharacters);
    }

    /**
     * Checks to see if string entered is a valid email format. This also validated comma delimited values.
     * @param $string
     */
    isValidEmail(email: string | null): boolean {
        var regex = /(([!#-'*+\-\/-9=?A-Z^-~]+[.])*[!#-'*+\-\/-9=?A-Z^-~]+@(((?:(?:[\da-zA-Z](?:[-\da-zA-Z]{0,61}[\da-zA-Z])?)\.)+(?:[a-zA-Z](?:[-\da-zA-Z]*[\da-zA-Z])))|(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])|(0[xX]0*[\da-fA-F]?[\da-fA-F]\.){3}0[xX]0*[\da-fA-F]?[\da-fA-F]|(0+[0-3][0-7][0-7]\.){3}0+[0-3][0-7][0-7]|(0|[1-9]\d{0,8}|[1-3]\d{9}|4[01]\d{8}|42[0-8]\d{7}|429[0-3]\d{6}|4294[0-8]\d{5}|42949[0-5]\d{4}|429496[0-6]\d{3}|4294967[01]\d{2}|42949672[0-8]\d|429496729[0-5])|0[xX]0*[\da-fA-F]{1,8}|([\da-fA-F]{1,4}\:){7}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}\:){6}((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])))\s*[,]\s*)*([!#-'*+\-\/-9=?A-Z^-~]+[.])*[!#-'*+\-\/-9=?A-Z^-~]+@(((?:(?:[\da-zA-Z](?:[-\da-zA-Z]{0,61}[\da-zA-Z])?)\.)+(?:[a-zA-Z](?:[-\da-zA-Z]*[\da-zA-Z])))|(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])|(0[xX]0*[\da-fA-F]?[\da-fA-F]\.){3}0[xX]0*[\da-fA-F]?[\da-fA-F]|(0+[0-3][0-7][0-7]\.){3}0+[0-3][0-7][0-7]|(0|[1-9]\d{0,8}|[1-3]\d{9}|4[01]\d{8}|42[0-8]\d{7}|429[0-3]\d{6}|4294[0-8]\d{5}|42949[0-5]\d{4}|429496[0-6]\d{3}|4294967[01]\d{2}|42949672[0-8]\d|429496729[0-5])|0[xX]0*[\da-fA-F]{1,8}|([\da-fA-F]{1,4}\:){7}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}\:){6}((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])))\s*[,]?\s*/;

        if (this.isNullOrWhiteSpace(email)) {
            return false;
        }
        var result = regex.exec((email || "").toLowerCase());

        if (result != null) {
            return result[0] == (email || "").toLowerCase();
        }
            
        return false;
    }
}

const StringHelpers = new StringHelperMethods();
export default StringHelpers;