export interface GoogleAddressComponentValueMap {
    [key: string]: string;
    street_number: string;
    subpremise: string;
    route: string;
    locality: string;
    postal_town: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    postal_code_prefix: string;
};

export function createGoogleAddressComponentValueMap(paramObj?: GoogleAddressComponentValueMap){
    const obj = <GoogleAddressComponentValueMap>{
        street_number: "",
        subpremise: "",
        route: "",
        locality: "",
        postal_town: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        postal_code_prefix: ""
    };

    if(paramObj){
        Object.assign(obj, paramObj);
    }
    return obj;
}