// mixin.js
import Vue from 'vue'
import Component from 'vue-class-component'

// You can declare a mixin as the same style as components.
@Component
export default class ComboboxHighlightMixin extends Vue {
    pointer : number = 0;
    resultCount: number = 0;

    get visibleOptions() {
        if (!this.$refs.options)
            return 0;

        const option : any = (<any>this.$refs.options)[0];
        const liH = (<HTMLElement>option).clientHeight;
        const containerHeight = (<HTMLElement>this.$refs.scrollContainer).clientHeight;
        return Math.floor(containerHeight/liH);
    }

    pointerForward() {
        if (this.pointer < this.resultCount-1) { 
            this.pointer = this.pointer + 1;
            this.fixScrolling();
        }
    }
    pointerPageForward() {
        if (this.pointer < this.resultCount-1) { 
            var optionsToMove = Math.min(this.visibleOptions, this.resultCount-this.pointer);
            this.pointer = this.pointer + optionsToMove;
            this.fixScrolling();
        }
    }
    pointerBackward() {
        if (this.pointer > 0) {
            this.pointer = this.pointer - 1;
            this.fixScrolling()
        }
    }
    pointerPageBackward() {
        if (this.pointer > 0) {
            var optionsToMove = Math.min(this.visibleOptions, this.pointer);
            this.pointer = this.pointer - optionsToMove;
            this.fixScrolling()
        }
    }
    fixScrolling(){
        const option : any = (<any>this.$refs.options)[this.pointer];
        const liH = (<HTMLElement>option).clientHeight;

        const centerElement = Math.floor(this.visibleOptions / 2);

        (<HTMLElement>this.$refs.scrollContainer).scrollTop = liH * (this.pointer-centerElement);
    }
    setPointer(index : number ) {
        this.pointer = index;
        this.fixScrolling();
    }
}