import Vue from 'vue';

Vue.filter('currency', (input: string, symbol: string) => {
    symbol = symbol ? symbol : '$';
    const defaultVal = `${symbol}0.00`;
    if (!input) {
        return defaultVal;
    }

    const numeral = parseFloat(input);
    if (Number.isNaN(numeral)) {
        return defaultVal;
    }

    const val = numeral.toFixed(2);
    return `${symbol}${val.toString()}`;
})