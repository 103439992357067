import { DirectiveOptions } from 'vue';

const regexPattern: DirectiveOptions = {
    bind(el, binding) {
        let inputEl = <HTMLInputElement>el;
        const regex = <RegExp>binding.value;
        inputEl.dataset["previousValue"] = inputEl.value;
        
        inputEl.addEventListener('paste', (event) => {
            if (!event.clipboardData)
                return;
            let paste = (event.clipboardData).getData('text');
            if (!regex.test(paste)) {
                event.preventDefault();
                return false;
            }
        });
        inputEl.addEventListener('keypress', (event) => {
        
            var key = event.keyCode || event.which;
            var keyString = String.fromCharCode(key);
            var newValue = inputEl.value + keyString;
            

            if (!regex.test(newValue)) {
                event.returnValue = false;
                if (event.preventDefault)
                    event.preventDefault();
            }
        })
    }
};

export default regexPattern;