import { DtoCustomerCustomField } from '../AllTypesExports';
import { DtoProduct } from './DtoProduct';
import { ProductType } from './ProductType';

export interface DtoProductQuantity {
    id: number,
	quantity: number,
	price: number,
    autoRenew: boolean,
    customFieldValues: { [key: string]: DtoCustomerCustomField },
    isDonation: boolean;
    taxable: boolean;
}

export class ProductQuantity implements DtoProductQuantity {
    id!: number;
    quantity!: number;
    price!: number;
    autoRenew!: boolean;
    customFieldValues!: { [key: string]: DtoCustomerCustomField; };
    isDonation!: boolean;
    taxable!: boolean;

    constructor(dtoProductQuantity: DtoProductQuantity) {
        Object.assign(this, dtoProductQuantity);
    }

    get subTotal() {
        return this.price * this.quantity;
    }

    get taxableSubTotal() {
        if (this.taxable) {
            return this.subTotal;
        }
        return 0;
    } 
}

export function createProductQuantity(dtoProductQuantity?: DtoProductQuantity | any) {
    const result = <DtoProductQuantity>{
        id: 0,
        quantity: 0,
        price: 0,
        autoRenew: false,
        customFieldValues: {},
        isDonation: false,
        taxable: false
    };
    if (dtoProductQuantity) {
        Object.assign(result, dtoProductQuantity);
    }
    return new ProductQuantity(result);
}

export function createProductQuantityFromProduct(dtoProduct: DtoProduct, isMember: boolean) {
    const result = <DtoProductQuantity>{
        id: dtoProduct.id,
        quantity: dtoProduct.productType == ProductType.Donation && dtoProduct.memberPrice > 0 ? 1 : 0,
        price: dtoProduct.productType == ProductType.Donation || isMember ? dtoProduct.memberPrice : dtoProduct.nonMemberPrice,
        autoRenew: dtoProduct.isSubscription,
        customFieldValues: {},
        isDonation: dtoProduct.productType == ProductType.Donation,
        taxable: dtoProduct.taxable
    };

    return new ProductQuantity(result);
}
