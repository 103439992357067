export interface CustomerInvoiceValidation {
    invoicingAllowed: boolean; 
    customerName: string; 
    daysOverdue: number;
    balance: number;
}

export function createCustomerInvoiceValidation(instance?: CustomerInvoiceValidation | any) {
    const obj = <CustomerInvoiceValidation>{
        balance: 0,
        customerName: '',
        daysOverdue: 0,
        invoicingAllowed: true
    };

    if (instance) {
        Object.assign(obj, instance);
    }
    return obj;
}