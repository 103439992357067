import { Component, Vue, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import { PropOptions } from 'vue';
import { createPhoneFormatOptions, PhoneFormatOptions, VeeValidationRules, createVeeValidationRules } from '../../../common/types/AllTypesExports';
import StringHelpers from '../../../common/services/StringHelpers';


@Component({
    name: 'PhoneInput'
})
export default class PhoneInput extends Vue {
    @Prop({ required: true, default: '' } as PropOptions<string>)
    value!: string;

    @Prop({ required: true, default: '' } as PropOptions<string>)
    phoneLabel!: string;

    @Prop({ required: false, default: true } as PropOptions<boolean>)
    includeParens!: boolean;

    @Prop({ required: false, default: false } as PropOptions<boolean>)
    allowExtensions!: boolean;

    @Prop({ required: false, default: '-' } as PropOptions<string>)
    delimiterText!: string;

    @Prop({ required: false, default: 'x' } as PropOptions<string>)
    extensionPrefix!: string;

    @Prop({ required: false, default: 21 } as PropOptions<number>)
    max!: number;

    @Prop({ required: false, default: false } as PropOptions<boolean>)
    isRequired!: boolean;

    @Prop({ required: false, default: null } as PropOptions<string>)
    validatorScope!: string;

    @Prop({ required: false, default: '' } as PropOptions<string>)
    id!: string;

    @Prop({ required: false, default: false } as PropOptions<boolean>)
    disabled!: boolean;

    @Inject('validator')
    $validator: any;

    allowInternational: boolean = false;
    currentValue: string = '';
    cleanExtensionEvents = ['deleteContentBackward', 'deleteContentForward', 'insertFromPaste'];

    get placeholderText() {
        const stringBuilder: string[] = [];

        if (!this.allowInternational) {
            stringBuilder.push(this.includeParens ? "(###)" : `###${this.delimiterText}`)
            stringBuilder.push(`###${this.delimiterText}####`);
            stringBuilder.push(this.allowExtensions ? ` ${this.extensionPrefix}####` : "");
        }
        else {
            stringBuilder.push("+1###-###-####...");
        }

        const result = stringBuilder.join('');
        return result;
    }

    get showLabel() {
        return !this.phoneLabel ? false : true;
    }

    get rules() {
        return {
            required: this.isRequired,
            max: this.max
        }
    }

    get minLength() {
        return this.allowInternational ? 1 : 10 + (this.includeParens ? 2 : 0) + this.delimiterText.length;
    }

    emitInput(evt: any, currentId: string) {
        if (currentId !== this.id)
            return;

        // This checks to see if the user is entering a backspace, delete key, or paste
        if (evt && this.cleanExtensionEvents.includes(evt.inputType)) {
            var isPaste = evt.inputType === 'insertFromPaste';

            if (isPaste) {
                this.cleanExtension(false);
            } else {
                this.cleanExtension(true);
                return;
            }
        }

        const formatOptions = createPhoneFormatOptions(<PhoneFormatOptions>{
            allowExtensions: this.allowExtensions,
            allowInternational: this.allowInternational,
            delimiterText: this.delimiterText,
            includeParens: this.includeParens,
            extensionPrefix: this.extensionPrefix,
            maxCharacters: this.max
        });
        this.value = StringHelpers.formatPhone(this.value, formatOptions);
        this.currentValue = this.value;
        this.$emit('input', this.value);
    }

    focus(isFocused: boolean) {
        /**************
         * Leaving this here in case we find that the old phone 
         * validation depended on a blank values instead of incomplete 
         * phone numbers
         **************/
        if (isFocused && !this.value) {
            this.value = this.currentValue;
            this.$emit('input', this.value);
        }
        else if (!isFocused) {//&& this.value.length < this.minLength
            this.value = !this.currentValue ? '' : this.currentValue;
            this.cleanExtension(true);

            // Handle custom error message for min length
            this.$validator.errors.remove(this.id, this.validatorScope);

            var validateName = this.id;

            if (this.validatorScope) {
                validateName = this.validatorScope + "." + this.id;
            }

            this.$validator.validate(validateName).then(() => {
                if (this.value && this.value.length < this.minLength) {
                    this.$validator.errors.add({
                        field: this.id,
                        msg: "The " + this.phoneLabel + " field must be at least 10 digits.",
                        scope: this.validatorScope
                    });
                }
            });
        }
    }

    toggleAllowInternational() {
        this.allowInternational = !this.allowInternational;
    }

    cleanExtension(emitUpdate: boolean) {
        this.value = this.value.trim();

        if (this.value.endsWith('x')) {
            this.value = this.value.slice(0, -1).trim();
        }

        this.currentValue = this.value;
        if (emitUpdate) {
            this.$emit('input', this.value);
        }
    }

    get isInternationalNumber() {
        if (!this.value)
            return this.allowInternational;

        const isValueInternational = this.includeParens
            ? !(new RegExp(`^\\(\\d{3}\\)\\d{3}${this.delimiterText}\\d{4}` + (this.allowExtensions ? `( ${this.extensionPrefix}[\\s]?\\d{1,6})?` : "") + "$").test(this.value))
            : !(new RegExp(`^\\d{3}${this.delimiterText}\\d{3}${this.delimiterText}\\d{4}` + (this.allowExtensions ? `( ${this.extensionPrefix}[\\s]?\\d{1,6})?` : "") + "$").test(this.value));
        return isValueInternational;
    }

    mounted() {
        this.allowInternational = this.isInternationalNumber;
        this.currentValue = this.value;
    }
}