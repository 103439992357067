import { Attendee } from './event/DtoAttendee';

export interface SelectListItemWithSubtext {
    id: number;
    text: string;
    subText: string | null;
    image: string | null;
    $isDisabled: boolean;
    taxable: boolean;
}

export class AttendeeSelectListItem implements SelectListItemWithSubtext {
    id!: number;
    text!: string;
    subText!: string | null;
    image!: string | null;
    isEligible!: boolean;
    hasAttended!: boolean;
    $isDisabled!: boolean;
    taxable!: boolean;

    constructor(attendee: Attendee, ticketID?: number | null) {
        this.id = attendee.customerId || 0;
        this.text = attendee.name || '';
        this.subText = attendee.email || '';
        this.image = attendee.image || '';
        this.isEligible = ticketID ? attendee.isEligibleFor(ticketID) : true;
        this.hasAttended = ticketID ? attendee.hasAlreadyPurchased(ticketID) : false;
        this.$isDisabled = !this.isEligible || this.hasAttended;
        this.taxable = true;
    }
}

export function createSelectListItemWithSubtext(dtoBasicCustomer?: SelectListItemWithSubtext | any) {
    let obj = <SelectListItemWithSubtext>{
        id: 0,
        text: '',
        subText: '',
        image: '',
        $isDisabled: false,
        taxable: true
    };

    if (dtoBasicCustomer) {
        Object.assign(obj, dtoBasicCustomer);
    }

    return obj;
}

export function createSelectListItemWithSubtextFromAttendee(attendee?: Attendee, ticketID?: number | null) {
    let obj = <SelectListItemWithSubtext>{};

    if (attendee) {
        obj.id = attendee.customerId || 0;
        obj.text = attendee.name || '';
        obj.subText = attendee.email || '';
        obj.image = attendee.image || '';
        obj.$isDisabled = ticketID ? !attendee.isEligibleFor(ticketID) : false;
        obj.taxable = true;
    }

    return obj;
}