import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ErrorAlert'
})
export default class ErrorAlert extends Vue {
    @Prop({ required: false, default: "" })
    message!: string;

    @Prop({ required: false, default: () => "" })
    details!: string;

    get messageLines() {
        return this.message.split("<br />");
    }
}