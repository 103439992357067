import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { qbPaymentsRepo } from '@/common/repositories/QuickbooksPaymentsRepo';
import { CardType } from '@/common/types/Payment/CardType';
import { AxiosResponse } from 'axios';
import { QboApiErrorResponse, createBasicSelectListItem, CreditCardTokenData, getQboErrorMessage } from '@/common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';

declare let noviNs: INoviNsPaymentGlobals;

@Component({
    name: 'QuickBooksPaymentsCardInput',
    components: {
        FieldValidationErrors
    }
})
export default class QuickBooksPaymentsCardInput extends Vue {
    @Prop({ required: false, default: () => true })
    creditCardActive!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    private lastCardNumber: string = '';
    cardNumber: string = '';
    expirationMonth: string = '';
    expirationYear: string = '';
    cvv: string = '';
    
    @Inject('validator')
    $validator: any;

    get cardMonths() {
        return [
            createBasicSelectListItem({ displayName: "1 - January",   id: "01" }),
            createBasicSelectListItem({ displayName: "2 - February",  id: "02" }),
            createBasicSelectListItem({ displayName: "3 - March",     id: "03" }),
            createBasicSelectListItem({ displayName: "4 - April",     id: "04" }),
            createBasicSelectListItem({ displayName: "5 - May",       id: "05" }),
            createBasicSelectListItem({ displayName: "6 - June",      id: "06" }),
            createBasicSelectListItem({ displayName: "7 - July",      id: "07" }),
            createBasicSelectListItem({ displayName: "8 - August",    id: "08" }),
            createBasicSelectListItem({ displayName: "9 - September", id: "09" }),
            createBasicSelectListItem({ displayName: "10 - October",  id: "10" }),
            createBasicSelectListItem({ displayName: "11 - November", id: "11" }),
            createBasicSelectListItem({ displayName: "12 - December", id: "12" })
        ];
    }

    get cardYears() {
        const currentYear = new Date().getFullYear();
        var years = [];
        var year: number;

        for (year = currentYear; year < currentYear + 10; year++) {
            years.push(year.toString());
        }

        return years;
    }
    
    get trimmedCardNumber() {
        return this.cardNumber.replace(/\s/g, '');
    }
   
    get cardType() {
        var number = this.trimmedCardNumber;

        var re = {
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5][0-9]{14}$/,
            amex: /^3[47][0-9]{13}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/
        };

        if (re.visa.test(number))
            return CardType.visa;
        if (re.mastercard.test(number))
            return CardType.masterCard;
        if (re.amex.test(number))
            return CardType.amex;
        if (re.discover.test(number))
            return CardType.discover;

        return null;
    }    
    
    get isVisa() {
        return this.cardType == CardType.visa;
    }

    get isMasterCard() {
        return this.cardType == CardType.masterCard;
    }

    get isAmex() {
        return this.cardType == CardType.amex;
    }

    get isDiscover() {
        return this.cardType == CardType.discover;
    }

    setCursorPosition(el: any, pos: number) {
        el.focus();
        el.setSelectionRange(pos, pos);
    }

    filterNumeric(e: KeyboardEvent) {
        var key = e.charCode || e.keyCode || 0;
        var ctrlDown = e.ctrlKey || e.metaKey;
        if (!(key == 8 || key == 9 || key == 13 || key == 46 || (key >= 37 && key <= 40) || (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key == 109 || key == 110 || key == 188 || key == 189 || key == 190 || (ctrlDown && key == 86))) {
            return e.preventDefault();
        }
    }    

    formatCardNumber() {
        var cardNumberElement: HTMLInputElement = <HTMLInputElement>this.$refs.cardNumber;

        // handle value-edit event
        if (cardNumberElement && cardNumberElement.selectionStart && cardNumberElement.selectionStart < this.cardNumber.length) {
            const startPos = cardNumberElement.selectionStart;
            this.cardNumber = this.cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim();
            this.$nextTick(() => this.setCursorPosition(cardNumberElement, startPos));
            this.lastCardNumber = this.cardNumber;
            return;
        }

        // handle everything else
        this.cardNumber = this.cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim();
        this.lastCardNumber = this.cardNumber;
    }

    onCardNumberUpdated() {        
        this.formatCardNumber();
        this.$emit('on-card-change');
    }
    
    createQbPaymentToken(card: CreditCardTokenData) {
        const qbCard = {
            card: {
                name: card.nameOnCard,                
                address: {
                    streetAddress: card.billingStreet,
                    city: card.billingCity,
                    region: card.billingState,
                    postalCode: card.billingZip,                    
                    country: "US"
                },
                number: this.trimmedCardNumber,
                expYear: this.expirationYear,
                expMonth: this.expirationMonth,
                cvc: this.cvv
                /* TODO: Include field for obfuscated credit card number - e.g. VISA xx-1234 */
            }
        }
        
        return qbPaymentsRepo.getQuickbooksPaymentToken(qbCard)
            .then((token: string) => {
                card.cardToken = token;
                card.last4Digits = qbCard.card.number.substr(-4);
                this.emitPaymentToken(card);
            })
            .catch((axiosResponse: AxiosResponse<QboApiErrorResponse>) => {
                // We are logging axiosResponse object to the console
                console.log(axiosResponse);
                this.emitError(`There was an issue validating your credit card information. Details: ${getQboErrorMessage(axiosResponse.data)}`);                
            });
    }
    
    emitPaymentToken(card: CreditCardTokenData) {
        this.$emit('on-payment-token-ready', card);
    }

    emitError(errorMsg: string) {
        this.$emit('on-error', errorMsg);
    }
    
    created() {

        if (!noviNs.quickbooksPaymentsUrl) {
            this.emitError("QuickBooks is choosen as the payment processor, but no QuickBooks payments URL was found. Please contact an admin.");
        }
    }
}