import {PaymentTypeEnum} from './PaymentTypeEnum';

export interface InvoiceTokenData {
    billablePartyId: number;
    salesTermId: number | null;
    waiveInvoiceFee: boolean;
    taxable: boolean;
}

export interface CreditCardTokenData {
    storedCardId: string;
    cardToken: string;    
    nameOnCard: string;
    billingStreet: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    billingCountry: string;
    saveCard: boolean;
    autoPay: boolean;
    last4Digits: string;
    billablePartyId: number;
}

export interface AchTokenData {
    storedBankAccountId: string;
    achToken: string;
    nameOnAccount: string;
    routingNumber: string;
    accountNumber: string;
    confirmAccountNumber: string;
    accountType: string;
    phone: string;
    authorizationChecked: boolean;
    saveAch: boolean;
    autoPay: boolean;
    last4Digits: string;
    billablePartyId: number;
}

export interface PaymentContainerToken {
    paymentType: PaymentTypeEnum;
    invoiceTokenData: InvoiceTokenData;
    creditCardTokenData: CreditCardTokenData;
    achTokenData: AchTokenData;
    idempotencyKey: string;
}

export function createPaymentContainerToken(instance?: PaymentContainerToken | any) {
    const obj = <PaymentContainerToken>{
        paymentType: PaymentTypeEnum.creditCard,
        invoiceTokenData: createInvoiceTokenData(),
        creditCardTokenData: createCreditCardTokenData(),
        achTokenData: createAchTokenData(),
        idempotencyKey: ''
    };

    if (instance) {
        Object.assign(obj, instance);
    }

    return obj;
}

export function createCreditCardTokenData(instance?: CreditCardTokenData | any) {
    const obj = <CreditCardTokenData>{
        storedCardId: '',
        cardToken: '',
        nameOnCard: '',
        billingStreet: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        saveCard: false,
        autoPay: false,
        billablePartyId: 0
    };

    if (instance) {
        Object.assign(obj, instance);
    }

    return obj;
}

export function createInvoiceTokenData(instance?: InvoiceTokenData | any) {
    const obj = <InvoiceTokenData>{
        billablePartyId: 0,
        salesTermId: null,
        waiveInvoiceFee: false
    };

    if (instance) {
        Object.assign(obj, instance);
    }

    return obj;
}

export function createAchTokenData(instance?: AchTokenData | any) {
    const obj = <AchTokenData>{
        storedBankAccountId: '',
        achToken: '',
        nameOnAccount: '',
        routingNumber: '',
        accountNumber: '',
        confirmAccountNumber: '',
        accountType: '',
        phone: '',
        saveAch: false,
        authorizationChecked: false,
        autoPay: false,
        billablePartyId: 0
    };

    if (instance) {
        Object.assign(obj, instance);
    }

    return obj;
}