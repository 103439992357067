import { Vue } from 'vue-property-decorator';
import { DtoMemberField } from '../types/AllTypesExports';
import NoviLogging from './NoviLogging';

class ValidationService {
     
    /**
     * Parses the given list of validation errors and adds them to the given validator's error bag
     * @param validationErrors The list of errors (generally returned from the backend)
     * @param scope The scope of the errors
     * @param fields A dictionary of the member fields found on the page
     * @param $validator The veeValidate validator
     */
    parseValidationErrors(validationErrors: { [key: string]: string }, scope: string, fields: { [key: string]: DtoMemberField }, $validator: any) {
        for (var fullFieldName in validationErrors) {
            var fieldName: string = fullFieldName.slice(fullFieldName.lastIndexOf('.') + 1).toLowerCase();
            var errorMessage = validationErrors[fullFieldName];
            if (errorMessage) {
                var errorList = errorMessage.split(';');
                for (var i = 0; i < errorList.length; i++) {
                    const memberField = fields[fieldName] || fieldName;
                    const memberFieldName = memberField.name ? memberField.name.toLowerCase() : fieldName;
                    const fieldError = errorList[i];
                    Vue.nextTick().then(() => { this.addError(memberFieldName, fieldError, scope, $validator); });
                }
            }
        }
    }

    /**
     * Adds an error to the given validator's error bag and marks the field as invalid
     * @param field The field name
     * @param msg The error message to show
     * @param scope The scope of the error, e.g. "Individual" or "Company"
     * @param $validator The veeValidate validator
     */
    addError(field: string, msg: string, scope: string, $validator: any) {
        NoviLogging.info(`Validation error for field "${field}": (${msg})`);

        $validator.errors.add({
            field: field,
            msg: msg || "Field is invalid",
            scope: scope
        });

        var htmlField = $validator.fields.find({ name: field, scope: scope });
        if (htmlField)
            htmlField.setFlags({ "invalid": true });
    }

    /**
     * Scrolls the user's browser to the first invalid input field
     * @param $el The base element from which invalid inputs will be searched
     */
    scrollToFirstInvalidField($el: Element) {
        Vue.nextTick().then(() => {
            var firstErrorMessage = $el.querySelector("div[role='alert']");
            if (firstErrorMessage) {
                // We get the error alert first, then work backward to find the input to focus on
                var errorElement = firstErrorMessage.previousElementSibling;
                while (errorElement && errorElement.nodeName != "DIV" && errorElement.nodeName != "INPUT" && errorElement.nodeName != "TEXTAREA") { // In the case of a Multiselect, it'll be a div, otherwise we want the input
                    errorElement = errorElement.previousElementSibling;
                }

                if (errorElement) {
                    (<HTMLElement>errorElement).scrollIntoView({ block: "center" });
                    (<HTMLElement>errorElement).focus();
                }
            }            
        });        
    }
}

const Validation = new ValidationService();
export default Validation;