import { Component, Vue, Prop, Emit, Watch, Inject } from 'vue-property-decorator';
import { PropOptions } from 'vue';
import { createPaymentProcessorOptions, PaymentProcessorOptions, CustomerInvoiceValidation, createCustomerInvoiceValidation, InvoiceTokenData, ApiErrorResponse, BasicSelectListItem, createBasicSelectListItem } from '@/common/types/AllTypesExports';
import { Multiselect } from 'vue-multiselect';
import CustomerSelector from '../membership/CustomerSelector.vue';
import { paymentsRepo } from '@/common/repositories/PaymentsRepo';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import WarningAlert from '../../components/errors/WarningAlert.vue';
import { AxiosResponse } from 'axios';

@Component({
    name: 'InvoiceContainer',
    components: {
        Multiselect,
        CustomerSelector,
        FieldValidationErrors,
        WarningAlert
    }
})
export default class InvoiceContainer extends Vue {
    @Prop({ required: true, default: () => createPaymentProcessorOptions() } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Inject('validator')
    $validator: any;

    invoiceTokenData: InvoiceTokenData = {
        billablePartyId: this.processorOptions.billablePartyId,
        salesTermId: this.processorOptions.salesTermId,
        waiveInvoiceFee: false,
        taxable: true
    };

    billableParties: BasicSelectListItem[] = [];
    billableParty = createBasicSelectListItem();
    paymentTerms: BasicSelectListItem[] = [];
    selectedPaymentTerm = createBasicSelectListItem();
    errorMessage: string = '';
    paymentTermsErrorMsg: string = '';
    customerInvoiceValidation: CustomerInvoiceValidation = createCustomerInvoiceValidation(<CustomerInvoiceValidation>{ invoicingAllowed: true });
    localInvoiceWarningMessage: string = '';
    isCheckingForBillableParties: boolean = false;
    noBillablePartiesMessage: string = 'No eligible billable parties found for invoicing. If you believe this to be an error, please contact us.';
    fetchingInvoiceData: boolean = false;

    get invoicingAllowed() {
        return this.processorOptions.enableInvoice && this.customerInvoiceValidation.invoicingAllowed && !this.invoiceWarningMessage && !this.fetchingInvoiceData;
    }

    get invoiceWarningMessage() {
        return this.processorOptions.invoiceWarningMessage || this.localInvoiceWarningMessage || '';
    }

    get showInvoiceDisabledMessage() {
        return !this.customerInvoiceValidation.invoicingAllowed && !this.invoiceWarningMessage;
    }

    get invoiceDisabledMsg() {
        var isAdmin = window.location.pathname.startsWith('/admin/');

        if (this.customerInvoiceValidation.balance > 0) {
            return `${this.billableParty.displayName} has a ${this.customerInvoiceValidation.daysOverdue} days past due balance of $${this.customerInvoiceValidation.balance.toFixed(2)}.` + (!this.processorOptions.enableAdminOptions ? ' This balance must be resolved before further invoices can be created.' : '');
        }

        return isAdmin ? 'This account does not have invoicing privileges.' : 'This account may not request invoices. Please contact us with questions.';
    }

    get showWaiveInvoiceFeeCheckbox() {
        return this.processorOptions.enableAdminOptions && this.processorOptions.invoiceFee > 0;
    }

    get showInvoiceFeeAlert() {
        return !this.processorOptions.enableAdminOptions && this.processorOptions.invoiceFee > 0 && !this.invoiceWarningMessage && !this.showInvoiceDisabledMessage;
    }

    get showPaymentTerms() {
        return this.processorOptions.enableAdminOptions && !this.processorOptions.hideTerms;
    }

    @Emit('invoice-token-updated')
    emitInvoiceTokenUpdate() {
        return this.invoiceTokenData;
    }

    @Watch('invoicingAllowed')
    onInvoiceAllowedUpdated(invoicingAllowed: boolean) {
        this.$emit("update:invoicingAllowed", invoicingAllowed);
    }

    @Watch('billableParty')
    onBillablePartyChange(newBillableParty: BasicSelectListItem) {
        Object.assign(this.billableParty, newBillableParty);

        this.fetchingInvoiceData = true;
        return this.validateCustomerInvoicing()
            .then(() => {
                this.invoiceTokenData.billablePartyId = this.billableParty.id;
                this.invoiceTokenData.taxable = this.billableParty.taxable;
                this.emitInvoiceTokenUpdate();
                this.fetchingInvoiceData = false;
            });
    }

    // Watch for external changes to billablePartyId (change purchaser)
    @Watch('processorOptions.billablePartyId')
    onBillablePartyIdChange(newBillablePartyId: number) {
        if (isNaN(newBillablePartyId)) {
            this.updateBillToDropdownSelector(null);
        }
        else if (newBillablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('Invoice', newBillablePartyId)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';

                    if (!this.billableParties.length) {
                        this.localInvoiceWarningMessage = this.noBillablePartiesMessage;
                        return
                    }

                    const newBillableParty = this.billableParties.find(b => b.id === newBillablePartyId) ?? this.billableParties[0];
                    Object.assign(this.billableParty, newBillableParty);
                    this.onBillablePartyChange(this.billableParty);
                    this.updateBillToDropdownSelector(this.billableParty);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }
    }

    onPaymentTermsChange() {
        this.invoiceTokenData.salesTermId = this.selectedPaymentTerm ? this.selectedPaymentTerm.id : null;
        this.emitInvoiceTokenUpdate();
    }

    onWaiveInvoiceFeeToggled() {
        this.emitInvoiceTokenUpdate();
    }

    onInvoiceWarningUpdated(message: string) {
        this.processorOptions.invoiceWarningMessage = message;
    }

    emitErrorToggle(hasError: boolean) {
        this.$emit('on-error-toggle', hasError, this.errorMessage);
    }

    validateCustomerInvoicing() {
        // Reset the exisiting validation first so we don't temporarily show messages that don't make sense
        this.customerInvoiceValidation = createCustomerInvoiceValidation();

        // Exit early if no billable party selected
        if (!this.billableParty || !this.billableParty.id) {
            this.errorMessage = '';
            this.emitErrorToggle(false);
            return Promise.resolve();
        }

        // Validate selected billable party
        return paymentsRepo.validateCustomerCanBeInvoiced(this.billableParty.id)
            .then((customerValidation: CustomerInvoiceValidation) => {
                Object.assign(this.customerInvoiceValidation, customerValidation);
                this.errorMessage = '';
                this.emitErrorToggle(false);
            })
            .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                this.errorMessage = axiosResponse.data.message;
                this.emitErrorToggle(true);
                throw axiosResponse;
            });
    }

    mounted() {
        // In some cases the billable party is set beforehand, and the user is only able to pick from a predetermined list - e.g. member signup
        // In others, the billable party is a wide open customer selector and is initially empty - e.g. backend attendee registration
        if (this.invoiceTokenData.billablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('Invoice', this.processorOptions.customerForBillableParties ?? this.invoiceTokenData.billablePartyId)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';

                    if (!this.billableParties.length) {
                        this.localInvoiceWarningMessage = this.noBillablePartiesMessage;
                        return
                    }

                    const currentBillableParty = this.billableParties.find(b => b.isDefault) ?? this.billableParties[0];
                    Object.assign(this.billableParty, currentBillableParty);
                    this.onBillablePartyChange(this.billableParty);
                    this.updateBillToDropdownSelector(this.billableParty);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }

        if (this.showPaymentTerms) {
            paymentsRepo.getSalesTerms()
                .then((paymentTerms: BasicSelectListItem[]) => {
                    this.paymentTerms.splice(0, this.paymentTerms.length, ...paymentTerms);
                    if (this.processorOptions.defaultPaymentTermDate) {
                        let defaultPaymentTerm: BasicSelectListItem = {
                            id: -1,
                            displayName: this.processorOptions.defaultPaymentTermDate.toString(),
                            taxable: true,
                            isDefault: false,
                        };
                        this.paymentTerms.unshift(defaultPaymentTerm);
                    }

                    this.paymentTermsErrorMsg = '';
                    return this.processorOptions.defaultPaymentTermDate ? -1 : this.processorOptions.salesTermId ? Promise.resolve(this.processorOptions.salesTermId) : paymentsRepo.getDefaultSalesTerm(this.invoiceTokenData.billablePartyId);
                })
                .then((defaultSalesTermId: number) => {
                    this.invoiceTokenData.salesTermId = defaultSalesTermId ? defaultSalesTermId : this.invoiceTokenData.salesTermId;
                    const currentPaymentTerm = this.paymentTerms.find(st => st.id === this.invoiceTokenData.salesTermId);
                    if (currentPaymentTerm) {
                        Object.assign(this.selectedPaymentTerm, currentPaymentTerm);
                    }
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.paymentTermsErrorMsg = axiosResponse.data.message;
                });
        }
    }

    // Update the 'Bill To' drop down value if changed from outside the vue app
    updateBillToDropdownSelector(value: any) {
        const customerSelector = this.$refs.billablePartySelector as any;
        if (customerSelector && typeof customerSelector.setSelectedCustomer === 'function') {
            customerSelector.setSelectedCustomer(value);
        }
    }
}