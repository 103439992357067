import { LogEntry } from '../types/Infrastructure/LogEntry';
import StringHelpers from '../services/StringHelpers';
import { setTimeout, clearTimeout } from 'timers';
import dayjs from 'dayjs';

class LoggingStorageService {
    private loggingApiEndpoint = '';
    private localStorageKey = 'user-log';
    private isTimeoutSet = false;

    // This is the timeout variable we use to flush the log to the server. We initialize it with a call to setTimeout because
    // of the way TypeScript handles the typing of a Timeout variable type.
    private flushTimeout = setTimeout(() => { clearTimeout(this.flushTimeout); }, 0);

    get loggingApiUrl() {
        if (this.loggingApiEndpoint)
            return this.loggingApiEndpoint;
        return '';
    }

    set loggingApiUrl(val: string) {
        this.loggingApiEndpoint = val;
    }

    getUserLog(storageKey: string) {
        const userLogJson = localStorage.getItem(storageKey);
        if (StringHelpers.isNullOrWhiteSpace(userLogJson))
            return [];

        try {
            const userLogRecords: LogEntry[] = JSON.parse(<string>userLogJson);
            return userLogRecords;
        }
        catch (e) {
            console.log(e)
            return [];
        }
    }

    storeLogEntry(entry: LogEntry) {
        if (!entry)
            return;

        try {
            const uLog = this.getUserLog(this.localStorageKey);
            uLog.push(entry);
            const storageValue = JSON.stringify(uLog);
            localStorage.setItem(this.localStorageKey, storageValue);

            // Set a timeout to flush the log to the server in the next 10 seconds
            if (!this.isTimeoutSet) {
                this.isTimeoutSet = true;
                const self = this;
                this.flushTimeout = setTimeout(() => {
                    self.flushToServer(self.getUserLog, self.localStorageKey);
                    // Clear the timeout so we can handle any future flushing to the server
                    clearTimeout(self.flushTimeout);
                    self.isTimeoutSet = false;
                }, 10000);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    flushToServer(uLog: (storageKey: string)=> LogEntry[], storageKey: string) {
        try {
            const now = dayjs();
            const currentLog = uLog(storageKey);
            const userLogToFlush = currentLog.filter(ul => now.isSame(ul.timeStamp) || now.isAfter(ul.timeStamp));
            
            // Go ahead and put back any logs that have been added after the "now" timestamp
            const userLogToKeep = currentLog.filter(ul => now.isBefore(ul.timeStamp)).map(u => u);
            const storageValue = JSON.stringify(userLogToKeep);            
            localStorage.setItem(storageKey, storageValue);

            // TODO: Add logic to send the userLogToFlush array to the server
        } 
        catch (e) {
            console.error(e);
        }
    }
}

const LoggingStorage = new LoggingStorageService();
export default LoggingStorage;