export interface DtoUserAccount {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
}


export function createDtoUserAccount(dtoUserAccount?: DtoUserAccount | any) {
    const result = <DtoUserAccount>{
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: ''
    };

    if (dtoUserAccount) {
        Object.assign(result, dtoUserAccount);
    }
    return result;
}