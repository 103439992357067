export default interface Step {
    name: string | null;
    title: string | null;
    iconClass: string | null;
    completed: boolean;
}

export function createStep(obj?: Step | any) {
    const result = <Step>{
        name: null,
        title: null,
        iconClass: null,
        completed: false
    };

    if (obj) {
        Object.assign(result, obj);
    }
    return result;
}
