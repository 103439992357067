import { Component, Vue, Prop, Inject, Emit, Watch } from 'vue-property-decorator';
import { DtoCustomer } from '@/common/types/membership/DtoCustomer';
import { customerRepo } from '@/common/repositories/CustomerRepo';
import { DtoMemberType, createDtoMemberType, CustomerType } from '@/common/types/AllTypesExports';
import { IMembershipRenewalGlobals } from './MembershipRenewalGlobals';

declare let noviNs: IMembershipRenewalGlobals;

@Component({
    components: {
    },
})
export default class MemberTypeSelection extends Vue {
    @Prop({ required: false })
    selectedMemberType?: DtoMemberType;
    @Prop({ required: false, default: false })
    showCompanyTypes?: boolean;
    @Prop({ required: false, default: false })
    companyTypesAccessDenied?: boolean;


    @Inject('validator')
    $validator: any;

    memberTypes: DtoMemberType[] = [];
    localSelectedMemberType?: DtoMemberType;
    isLoading = false;
    hasError = false;
    errorMessage = "";

    created() {
        this.loadMemberTypes();
    }

    get filteredMemberTypes() {
        if (this.showCompanyTypes)
            return this.memberTypes;
        else
            return this.memberTypes.filter(mt => !mt.forCompanies);
    }

    get contactUsUrl() {
        return noviNs.contactUsUrl;
    }

    @Emit('update:selectedMemberType')
    emitSelectedMemberType(value : DtoMemberType) {
        return value;
    }

    @Watch("selectedMemberType", { immediate: true })
    selectedMemberTypeChanged(newValue : DtoMemberType) {
        this.localSelectedMemberType = this.memberTypes.find((mt) => { return this.selectedMemberType != undefined && mt.id == this.selectedMemberType.id });
    }

    loadMemberTypes() {
        this.isLoading = true;
        customerRepo.getMemberTypes()
        .then(result => {
            this.memberTypes = result;
            if (this.selectedMemberType) {
                //preselect current member type only if it is eligible for selection
                this.localSelectedMemberType = result.find((mt) => { return this.selectedMemberType != undefined && mt.id == this.selectedMemberType.id && mt.eligibleForSelection });
            }
        })
        .catch((message) => {
            this.hasError = true;
            this.errorMessage = message;
        })
        .finally(() => this.isLoading = false);
    }


}