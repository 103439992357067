import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import { CustomField, CustomFieldValue, CustomFieldType, createCustomField, DtoCustomer, createDtoCustomer, CustomFieldSection, createCustomFieldSection } from '../../common/types/AllTypesExports';
import CustomFieldEditor from './CustomFieldEditor.vue';

@Component({
    components: {
        CustomFieldEditor
    }
})

export default class CustomFieldSectionEditor extends Vue {
    //External
    @Prop({ required: true, default: () => createCustomFieldSection() })
    value!: CustomFieldSection;

    @Prop({ required: false, default: '' })
    scope!: string;

    @Prop({ required: false, default: "" })
    prefix?: string;

    @Prop({ required: true, default: () => createDtoCustomer() })
    customer!: DtoCustomer;

    get sectionTitle() {
        return this.value.name ? this.value.name : null;
    }
    get sectionDescription() {
        return this.value.description ? this.value.description : null;
    }

    get sectionCustomFields() {
        return this.value.customFields.filter(c => c.keyInformation);
    }

}