import { CustomField } from "./CustomField";

export interface CustomFieldSection {
    id: number | null,
    name: string | null,
    displayOrder: number | null,
    description: string | null,
    customFields: CustomField[]
}


export function createCustomFieldSection(customFieldSection?: CustomFieldSection | any) {
    const obj = <CustomFieldSection>{
        id: 0,
        name: '',
        displayOrder: 0,
        description: '',
        customFields: [],
    };
    if (customFieldSection) {
        Object.assign(obj, customFieldSection);
    }
    return obj;
}