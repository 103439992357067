import { Component, Vue, Prop, Emit, Inject, Watch } from 'vue-property-decorator';
import * as NoviTypes from '../../common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { Multiselect } from 'vue-multiselect';

@Component({
    components: {
        Multiselect,
        FieldValidationErrors
    },
})
export default class CustomFieldSetOfOptionsEditor extends Vue {
    //External
    @Prop({ required: true })
    fieldConfig!: NoviTypes.CustomField;

    @Prop({ required: true })
    value?: String;

    @Prop({ required: true })
    id!: string;

    @Prop({ required: false, default: ''})
    validatorScope!: string;

    @Prop({required: false, default: false})
    readOnly!: boolean;

    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;

    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    //Internal
    selectedValues: String[] = this.splitBySemicolon(this.value);
    maxSelections = this.fieldConfig.limitSelections && this.fieldConfig.limitSelectionsTo ? Math.max(this.fieldConfig.limitSelectionsTo, this.selectedValues.length) : null;

    //Functions
    created() {
        this.emitInput();
    }

    setAccessibilityAttributes() {
        let element = $(this.$el);
        setTimeout(function () {
            element.find('.multiselect__tag-icon').attr({ 'tabindex': '0', 'role': 'button', 'aria-label': 'Remove', 'title': 'Remove' });
        }, 50);
    }

    @Emit('open')
    dropdownOpened() {
        this.setAccessibilityAttributes();
    }

    @Emit('tag')
    tagAdded() {
        this.setAccessibilityAttributes();
    }

    @Watch('value')
    // If the value prop changes outside of this component (e.g. auto-population of an attendee custom field value) then the update needs to be passed to the multiselect, which is bound to selectedValues
    onValueUpdated() {
        this.selectedValues = this.splitBySemicolon(this.value);
    }

    @Emit('input')
    emitInput() {
        this.setAccessibilityAttributes();

        // Multi select
        if (Array.isArray(this.selectedValues)) {
            return this.selectedValues.join(";");
        }
        // Single select
        else {
            return this.selectedValues;
        }
    }

    splitBySemicolon(myString?: String) {
        return myString ? myString.split(";") : [];
    }

    get applySelectionLimit() {
        return this.fieldConfig.allowMultipleSelections && this.maxSelections && this.maxSelections < this.setOfOptions.length;
    }

    get placeholderText() {
        return this.selectedValues && this.selectedValues.length > 0 ? "" : "--Select an Option--";
    }

    get setOfOptions() {
        return this.splitBySemicolon(this.fieldConfig.setOfOptions);
    }

    get rules() {
        return {
            required: this.required
        }
    }

    get required() {
        return (this.isRequired == null && this.fieldConfig.requiredFrontend && !this.readOnly) || (this.isRequired != null && this.isRequired);
    }

    get showRestrictionsMessage() {
        return this.readOnly && !this.hideDisabledMessage;
    }
}