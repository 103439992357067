import { AutoRenewType } from './AutoRenewType';

export interface DtoMemberType {
    id: number;
    name: string;
    description: string | null;
    forCompanies: boolean;
    requireImmediatePayment: boolean;
    autoRenewType: AutoRenewType;
    eligibleForSelection: boolean;
    seatBased: boolean;
    seatMinimum: number | null;
    seatMaximum: number | null;
    requireApplication: boolean;
    hasPromoCodes: boolean;
}


export function createDtoMemberType(dtoMemberType?: DtoMemberType | any) {
    const result = <DtoMemberType>{
        id: 0,
        name: '',
        description: null,
        forCompanies: false,
        requireImmediatePayment: false,
        autoRenewType: AutoRenewType.Disabled,
        eligibleForSelection: true,
        requireApplication: false,
        hasPromoCodes: false
    };

    if (dtoMemberType) {
        Object.assign(result, dtoMemberType);
    }
    return result;
}