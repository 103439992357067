import { AxiosError, AxiosResponse } from 'axios';
import { ApiErrorResponse, QboApiErrorResponse, createLogEntry, LogEntry, LogMessageType, getQboErrorMessage } from "../types/AllTypesExports";
import LoggingStorage from './LoggingStorage';

class NoviLoggingService {
    toConsole(...args: any[]) {
        if (!this._hasArgs(args)) {
            return;
        }
        console.log("\n");
        args.forEach(item => console.log(item));
    }

    /**
     * Logs a simple information message to the console and interested third parties
     * @param msg Message to display
     * @param context Object that contains contextual metadata around this message.
     */
    info<TObj>(msg: string, context?: TObj) {
        const entry = createLogEntry(<LogEntry>{ message: msg, logMessageType: LogMessageType.Info });
        if (context) {
            entry.customData = context;
        }
        console.log("\n");
        console.log(entry.message);
        if (entry.customData) {
            console.log(entry.customData);
        }
        LoggingStorage.storeLogEntry(entry);
    }

    /**
     * Logs a simple warning message (and potential context object) to the console and to interested third parties.
     * @param msg Message to display
     * @param context Object that contains contextual metadata around this warning.
     */
    warn<TObj>(msg: string, context?: TObj) {
        const entry = createLogEntry(<LogEntry>{ message: msg, logMessageType: LogMessageType.Warning });
        if (context) {
            entry.customData = context;
        }
        console.log("\n");
        if (!entry.customData) {
            console.warn(entry.message, entry.customData);
        }
        else {
            console.warn(entry.message);
        }
        LoggingStorage.storeLogEntry(entry);
    }

    /**
     * Logs a simple error message (and potential context object) to the console and to interested third parties.
     * @param msg Message to display
     * @param context Object that contains contextual metadata around this error.
     */
    error<TObj>(msg: string, context?: TObj) {
        const entry = createLogEntry(<LogEntry>{ message: msg, logMessageType: LogMessageType.Error });
        console.log("\n");
        if (context) {
            entry.customData = { context };
            console.error(entry.message, entry.customData);
        }
        else {
            console.error(entry.message);
        }
        LoggingStorage.storeLogEntry(entry);
    }

    /**
     * Logs an exception to the console and to interested third parties.
     * @param error Javascript Error object. You can create a custom error by calling `new Error('some custom message').stack`. Reference https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
     * @param context Object that contains contextual metadata around this error.
     */
    exception<TObj>(error: Error, context?: TObj) {
        try {
            const entry = createLogEntry(<LogEntry>{ message: error.message, logMessageType: LogMessageType.Exception });
            if (context) {
                entry.customData = { context };
            }
            else {
                entry.customData = { context: 'no custom data' };
            }
            console.log("\n");
            console.error(entry.message, {error, customData: entry.customData});
            LoggingStorage.storeLogEntry(entry);
        } 
        catch (e) {
            // Swallow to avoid unnecessary exceptions
            console.error(e);
        }
    }

    /**
     * Logs a Debug message type to the console (only)
     * @param args Arguments to display on the console.
     */
    debug(...args: any[]) {
        if (!this._hasArgs(args)) {
            return;
        }
        console.log("\n");
        args.forEach(item => console.debug(item));
    }

    /**
     * Logs a Trace message to the console (only)
     * @param args Arguments to log to the console
     */
    trace(...args: any[]) {
        if (!this._hasArgs(args)) {
            return;
        }
        console.log("\n");
        args.forEach(item => console.trace(item));
    }

    /**
     * Takes an Axios error from a call to a Novi API and logs it
     * @param axiosError
     */
    noviApiError(axiosError: AxiosError) {
        const axiosResponse = <AxiosResponse<ApiErrorResponse>>axiosError.response;
        this.error(axiosResponse.data.message, axiosResponse.data);
        return axiosResponse;
    }

    /**
     * Takes an Axios error from a call to the QBO API and logs it
     * @param axiosError
     */
    qboApiError(axiosError: AxiosError) {
        const axiosResponse = <AxiosResponse<QboApiErrorResponse>>axiosError.response;
        this.error(getQboErrorMessage(axiosResponse.data), axiosResponse.data);
        return axiosResponse;
    }

    private _hasArgs(...args: any[]) {
        return args && args.length > 0;
    }
}

const NoviLogging = new NoviLoggingService();

export default NoviLogging;