import { DtoMemberField as MemberFieldItem } from "@/common/types/membership/DtoMemberField";
import { CustomField } from "@/common/types/CustomField/CustomField";
import { CustomFieldSection } from "@/common/types/CustomField/CustomFieldSection";


export interface CustomerFieldConfig {
    memberTypeId: number;
    memberFields: MemberFieldItem[];
    customFields: CustomField[];
    customFieldSections: CustomFieldSection[];
    counties: string[];
}

export function createCustomerFieldConfig(val?: CustomerFieldConfig | any) {
    const obj = <CustomerFieldConfig> {
        memberTypeId: 0,
        memberFields: [],
        customFields: [],
        customFieldSections: [],
        counties: []
    };

    if (val) {
        Object.assign(obj, val);
    }

    return obj;
}