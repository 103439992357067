import { DirectiveOptions } from 'vue';

const ariaDescribedById: DirectiveOptions = {
    bind: function (el, binding) {
        if (!binding.value) {
            return;
        }

        $(el).find('input').attr("aria-describedby", binding.value);
    }
};

export default ariaDescribedById;