import { Component, Vue, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import Step, { createStep } from './Step'
import PercentageRing from './PercentageRing.vue'
import { emit } from 'cluster';


@Component({
    name: 'NoviStepper',
    components: {
        PercentageRing
    }
})
export default class NoviStepper extends Vue {
    @Prop({ required: true })
    steps!: [Step];

    @Prop({default: createStep()})
    currentStep!: Step;

    @Prop({ default: true })
    allowStepClicks!: boolean;

    localCurrentStep: Step = createStep();
    previousCurrentStep: Step = createStep();

    created() {
    }

    @Watch('currentStep', { immediate: true })
    onCurrentStepChange(val: Step, oldVal: Step) {
        var stepIndex = this.steps.indexOf(val);
        if (stepIndex > -1 && stepIndex != this.currentStepIndex) {
            this.activateStep(stepIndex);
        }
    }

    @Emit("update:currentStep")
    setCurrentStep(step: Step) {
        this.localCurrentStep = step;
        return step;
    }

    get currentStepIndex(): number {
        return this.steps.indexOf(this.localCurrentStep);
    }

    get previousCurrentStepIndex(): number {
        return this.steps.indexOf(this.previousCurrentStep);
    }

    get currentSlotName(): string | null {
        return this.localCurrentStep.name;
    }

    get enterAnimation() {
        return "animated quick fadeIn";
    }
    get leaveAnimation() {
        return "animated quick fadeOut";
    }

    get slotNames() : (string|null)[] {
        return this.steps.map(step => {
            return step.name;
        });
    }

    isStepClickable(stepIndex: number) {
        if (!this.allowStepClicks)
            return false;
        if (this.currentStepIndex < 0)
            return false;

        return stepIndex != this.currentStepIndex && (stepIndex < this.currentStepIndex || this.steps[Math.max(stepIndex - 1, 0)].completed);
    }
    stepClicked(stepIndex: number) {
        if (this.isStepClickable(stepIndex)) {
            this.activateStep(stepIndex);
        }
    }

    activateStep(stepIndex: number) {
        if (stepIndex < 0)
            return;

        this.previousCurrentStep = this.localCurrentStep;
        this.localCurrentStep = this.steps[stepIndex];
        this.$emit("update:currentStep", this.localCurrentStep);
    }

    noviStepperEntered() {
        this.$emit("update:noviStepperEntered");
    }
}