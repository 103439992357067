import { Component, Vue, Prop, Watch, Inject, Emit } from 'vue-property-decorator';
import { ApiErrorResponse, DtoCustomer, createDtoCustomer } from '@/common/types/AllTypesExports';
import { customerRepo } from '../../common/repositories/CustomerRepo';
import { AxiosResponse } from 'axios';

@Component({
    components: {        
    }
})
export default class DuplicateChecker extends Vue {

    @Prop({ required: true, default: () => createDtoCustomer() })
    customer!: DtoCustomer;

    @Prop({ required: false, default: () => '' })
    memberVerificationCode!: string;

    @Prop({ required: false, default: () => false })
    emailIsAllowed!: boolean;

    @Prop({ required: false, default: () => false })
    personIsAllowed!: boolean;

    @Prop({ required: false, default: () => false })
    validateEmailOnly!: boolean;

    @Prop({ required: false, default: false })
    allowNonMemberAccountSignup!: boolean;

    @Prop({ required: false, default: true })
    allowMemberVerification!: boolean;

    verificationCode: string = this.memberVerificationCode;
    emailValidated: boolean = false; // Marked true once the server has returned a result indicating whether the email is valid or not
    validatingEmailAddress: boolean = false; // UI var

    wouldPersonInheritBenefits: boolean = false; // Marked true/false when no duplicate found
    personDuplicateFound: boolean = false; // Marked true or false once the server returns result indicating whether person duplicate exists
    personValidated: boolean = false; // Marked true once the server has returned a result indicating whether the person is valid or not
    validatingPerson: boolean = false; // UI var

    ignoreMemberVerification: boolean = !this.allowMemberVerification;  // When true, will hide the verification section
    ignoreDuplicateExistsDuringSignup: boolean = false; // When true, will hide the 'We've found a record that matches the information you provided.' message
    ignoreNonMemberAccountCreation: boolean = false; // When true, will hide the 'unable to find existing member' message
    userAccountAvailable: boolean = false;   // The email is not associated with any user accounts
    memberAccountAvailable: boolean = false; // The email is not associated with any member records    

    sendingVerificationCode: boolean = false; // UI var
    verificationCodeSent: boolean = false; // UI var - true once sent response comes back
    maskedEmail: string = ''; // The masked email that comes back after the verification message is sent
    isVerifyingCode: boolean = false; // UI var
    verifiedMemberRecord: boolean = false; // True once a response comes back from verification server
    memberAccountVerified: boolean = false; // Marked true if the user enters a correct verification code
    verificationErrorMessage: string = '';

    duplicateCustomerId: number = 0;
    duplicateFirstName: string = '';
    duplicateLastName: string = '';
    duplicateParentName: string = '';
    duplicateParentId: number = 0;
    duplicateEmail: string = '';
    duplicateUserAccountEmail: string = '';
    duplicateAlreadyHasAccount: boolean = false;

    originalCustomer: DtoCustomer = createDtoCustomer();

    @Inject('validator')
    $validator: any;

    mounted() {}

    get showUserAccountTaken() {
        return this.validateEmailOnly && (!this.showUserAccountAvailable && this.emailValidated);
    }

    get showUserAccountAvailable() {
        return this.validateEmailOnly && (this.emailValidated && this.userAccountAvailable);
    }   

    get showMemberAccountAvailable() {
        return this.validateEmailOnly && this.emailValidated && this.memberAccountAvailable;
    }

    get showEmailAddressAvailable() {
        return this.validateEmailOnly && (this.showUserAccountAvailable && this.showMemberAccountAvailable || !this.emailValidated);
    }

    get showMemberVerification() {
        return this.allowMemberVerification && !this.ignoreMemberVerification && this.userAccountAvailable && !this.memberAccountAvailable && this.duplicateEmail;
    }

    get showFoundExistingMissingEmail() {
        return this.duplicateCustomerId && !this.duplicateEmail;
    }

    get showFoundExistingAccount() {
        return !this.validateEmailOnly && this.duplicateCustomerId && this.duplicateEmail && !this.userAccountAvailable;
    }

    get showFoundExistingDuringSignup() {
        return !this.allowMemberVerification && !this.ignoreDuplicateExistsDuringSignup && this.userAccountAvailable && !this.memberAccountAvailable;
    }

    get emailIsAvailable() {
        return this.userAccountAvailable && (this.memberAccountAvailable || this.memberAccountVerified)
    }

    get sendVerificationCodeText() {
        return this.sendingVerificationCode ? "Sending..." : (this.verificationCodeSent || this.verificationErrorMessage) ? "Resend Verification Code" : "Email Verification Code"
    }

    get duplicateNameText() {
        return `${this.duplicateFirstName} ${this.duplicateLastName}${this.duplicateParentName ? " (" + this.duplicateParentName + ")" : ""}`;
    }

    get disableVerifyButton() {
        return this.isVerifyingCode || this.verifiedMemberRecord || !this.verificationCode;
    }

    get showUnableToFindExistingMember() {
        return this.customer.parentCustomerId != 0 && !this.ignoreNonMemberAccountCreation && this.personValidated && !this.personDuplicateFound && !this.wouldPersonInheritBenefits;
    }

    get showPasswordFields() {
        return !this.verificationCodeSent || this.verifiedMemberRecord;
    }

    reset() {
        if (this.memberAccountVerified) {
            this.originalCustomer.email = this.customer.email;
            Object.assign(this.customer, this.originalCustomer);
            this.emitCustomerUpdated();
        }

        this.validatingEmailAddress = false;
        this.userAccountAvailable = false;
        this.emailValidated = false;
        this.memberAccountAvailable = false;        
        this.ignoreMemberVerification = !this.allowMemberVerification;
        this.ignoreDuplicateExistsDuringSignup = false;
        this.ignoreNonMemberAccountCreation = false;
        this.verificationCodeSent = false;
        this.verifiedMemberRecord = false;
        this.verificationErrorMessage = '';

        this.duplicateCustomerId = 0;
        this.duplicateFirstName = "";
        this.duplicateLastName = "";
        this.duplicateParentName = "";
        this.duplicateParentId = 0;
        this.duplicateEmail = "";
        this.duplicateAlreadyHasAccount = false;

        this.emailIsAllowed = false;
        this.emitEmailIsAllowed(false);

        this.personIsAllowed = false;
        this.emitPersonIsAllowed(false);

        this.memberAccountVerified = false;
        this.emitMemberAccountVerified();
    }
    
    validateEmailAddress() {
        this.reset();
        this.validatingEmailAddress = true;
        var isNewParent = this.customer.parentCustomerId == 0;
        
        // If we don't have a value for email then we just cycle through a fake request
        if (!this.customer.email || isNewParent) {

            // If the parentCustomerId is 0 then we know we are creating a new parent and this has to be a unique customer
            if (this.customer.parentCustomerId == 0) {
                this.emitPersonIsAllowed(true);
            }

            return new Promise<void>((resolve, reject) => {
                const timeout = setTimeout(() => {
                    try {
                        this.validatingEmailAddress = false;
                        clearTimeout(timeout);
                        return resolve();
                    } catch (e) {
                        return reject(e);
                    }
                }, 250);
            });
        }

        // Call the API to check for a duplicate email among users and members/customers
        return customerRepo.checkForDuplicate(this.customer.email, '', '', null)
            .then((result) => {
                this.validatingEmailAddress = false;
                this.emailValidated = true;
                this.userAccountAvailable = result.userAccountAvailable;
                this.memberAccountAvailable = !result.customerId;

                if (result.hasDuplicate) {
                    if (result.customerId) {
                        this.duplicateCustomerId = result.customerId;
                        this.duplicateFirstName = result.firstName;
                        this.duplicateLastName = result.lastName;
                        this.duplicateParentName = result.parentName;
                        this.duplicateParentId = result.parentId;
                        this.duplicateEmail = result.email;
                        this.duplicateUserAccountEmail = result.userAccountEmail
                        
                    }
                    this.duplicateAlreadyHasAccount = result.hasNonMatchingUserAccount || !this.userAccountAvailable;
                    this.emitEmailIsAllowed(this.userAccountAvailable);
                }
                else {                   
                    this.emitEmailIsAllowed(true);
                }

                return result;
            })
            .catch((error: AxiosResponse<ApiErrorResponse>) => {
                this.errors.add({ field: 'account_email', id: 'account_email', msg: error.data.message });
                this.validatingEmailAddress = false;
                return error;
            });
    }

    validatePerson() {
        this.reset();
        this.validatingPerson = true;
        var isNewParent = this.customer.parentCustomerId == 0;

        // If we don't have a value for email then we just cycle through a fake request
        if (!this.customer.email || isNewParent) {
            // If the parentCustomerId is 0 then we know we are creating a new parent and this has to be a unique customer
            if (isNewParent) {
                this.emitPersonIsAllowed(true);
            }

            return new Promise<void>((resolve, reject) => {
                const timeout = setTimeout(() => {
                    try {
                        this.validatingPerson = false;
                        clearTimeout(timeout);
                        return resolve();
                    } catch (e) {
                        return reject(e);
                    }
                }, 250);
            });
        }

        // Call the API to check for a duplicate person among users and members/customers
        return customerRepo.checkForDuplicate(this.customer.email, this.customer.firstName, this.customer.lastName, this.customer.parentCustomerId)
            .then((result) => {
                this.validatingPerson = false;
                this.personValidated = true;
                this.personDuplicateFound = result.hasDuplicate;
                this.wouldPersonInheritBenefits = result.wouldReceiveBenefits;
                this.userAccountAvailable = result.userAccountAvailable;
                this.memberAccountAvailable = !result.customerId;

                if (result.hasDuplicate) {
                    // found existing member, save that member's info for verification step                   
                    if (result.customerId) {
                        this.duplicateCustomerId = result.customerId;
                        this.duplicateFirstName = result.firstName;
                        this.duplicateLastName = result.lastName;
                        this.duplicateParentName = result.parentName;
                        this.duplicateParentId = result.parentId;
                        this.duplicateEmail = result.email;
                        this.duplicateUserAccountEmail = result.userAccountEmail
                    }

                    this.duplicateAlreadyHasAccount = result.hasNonMatchingUserAccount || !this.userAccountAvailable;
                    this.emitPersonIsAllowed(this.userAccountAvailable && !!this.duplicateEmail);
                }
                else {
                    if (result.wouldReceiveBenefits) {
                        // unable to find existing member, allow account creation
                        this.emitPersonIsAllowed(true);
                    }
                    else {
                        if (this.allowNonMemberAccountSignup) {
                            // unable to find existing member, allow non-member account creation
                            this.emitPersonIsAllowed(true);
                        }
                        else {
                            // unable to find existing member, DO NOT allow non-member account creation                            
                            this.emitPersonIsAllowed(false);
                        }
                    }                    
                }
                
                return result;
            })
            .catch((error: AxiosResponse<ApiErrorResponse>) => {
                this.errors.add({ field: 'account_email', id: 'account_email', msg: error.data.message });
                this.validatingPerson = false;
                return error;
            });
    }

    sendVerificationCode() {
        this.sendingVerificationCode = true;
        this.verifiedMemberRecord = false;
        this.verificationErrorMessage = '';
        this.verificationCode = '';
        this.emitMemberVerificationCodeUpdated();
        customerRepo.sendMemberVerificationCode(this.duplicateCustomerId, this.customer.email)
            .then((result) => {
                this.verificationCodeSent = true;
                this.sendingVerificationCode = false;
                this.maskedEmail = result.maskedEmail;
                this.emitEmailIsAllowed(false);
            })
            .catch((error: AxiosResponse<ApiErrorResponse>) => {
                this.sendingVerificationCode = false;
                this.verificationErrorMessage = error.data.message;
                this.validatingEmailAddress = false;
                return error;
            });
    }

    verifyVerificationCode() {
        this.isVerifyingCode = true;
        this.verificationErrorMessage = '';
        customerRepo.confirmVerificationCode(this.duplicateCustomerId, this.verificationCode)
            .then((responseCustomer) => {
                this.memberAccountVerified = responseCustomer && responseCustomer.id > 0;
                this.isVerifyingCode = false;
                this.verifiedMemberRecord = true;
                this.emitEmailIsAllowed(true);
                if (this.memberAccountVerified) {
                    Object.assign(this.originalCustomer, this.customer);
                    Object.assign(this.customer, responseCustomer);
                    this.emitCustomerUpdated();
                    this.emitMemberAccountVerified();
                    this.emitMemberVerificationCodeUpdated();
                }
            })
            .catch((error: AxiosResponse<ApiErrorResponse>) => {
                this.isVerifyingCode = false;
                this.verificationErrorMessage = error.data.message;
                return error;
            });
    }
        
    emitEmailIsAllowed(isAllowed: boolean) {
        this.$emit('update:emailIsAllowed', isAllowed);
    }

    emitPersonIsAllowed(isAllowed: boolean) {
        this.$emit('update:personIsAllowed', isAllowed);
    }

    emitMemberVerificationCodeUpdated() {
        //const verificationCode = this.verificationCode;
        this.$emit('update:memberVerificationCode', this.verificationCode);
    }

    emitCustomerUpdated() {
        const customerToEmit = createDtoCustomer();
        this.$emit('update:customer', Object.assign(customerToEmit, this.customer));
    }

    emitMemberAccountVerified() {
        const accountVerified = this.memberAccountVerified;
        this.$emit('update:memberAccountVerified', accountVerified);
    }
}