import { DtoCustomerCustomField } from './DtoCustomerCustomField';
import { DtoAddress, createDtoAddress } from './DtoAddress';
import { DtoAward } from './DtoAward';
import { DtoDegree } from './DtoDegree';
import { DtoVolunteerWork } from './DtoVolunteerWork';
import { DtoMemberType } from './DtoMemberType';
import { CustomerType } from "./CustomerType";
import { MemberStatus } from './MemberStatus';


export interface DtoCustomer {
    id: number;
    name: string;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    customFields: { [key: string]: DtoCustomerCustomField };
    parentCustomerId: number | null;
    parentCustomerName: string | null;
    duesPayerId: number | null;
    email: string | null;
    phone: string | null;
    mobile: string | null;
    fax: string | null;
    website: string | null;
    county: string | null;
    billingAddress: DtoAddress | null;
    parentBilling: DtoAddress | null;
    useParentBilling: boolean | null;
    shippingAddress: DtoAddress | null;
    parentShipping: DtoAddress | null;
    useParentShipping: boolean | null;
    personalEmail: string | null;
    personalPhone: string | null;
    personalMobile: string | null;
    personalAddress: DtoAddress | null;
    facebookUrl: string | null;
    linkedInUrl: string | null;
    xHandle: string | null;
    instagramHandle: string | null;
    memberProfile: string | null;
    image: string | null;
    jobTitle: string | null;
    credentials: string | null;
    memberType: DtoMemberType | null;
    customerType: CustomerType | null;
    awards: DtoAward[];
    degrees: DtoDegree[];
    volunteerWork: DtoVolunteerWork[];
    membershipExpires: Date | null;
    memberSince: Date | null;
    incompleteSignup: boolean | null;
    membershipStatus: MemberStatus;
    totalSeats: number;
    assignedSeats: number;
    userId: string;
    autoRenew: boolean;
    autoPay: boolean;
    defaultBillablePartyId: number | null;
    approved: boolean;
}

export function createDtoCustomer(dtoCustomer?: DtoCustomer | any) {
    let obj = <DtoCustomer>{
        awards: [],
        billingAddress: createDtoAddress(),        
        county: '',
        credentials: '',
        customerType: CustomerType.Company,
        customFields: {},
        degrees: [],
        email: null,
        facebookUrl: null,
        fax: null,
        firstName: '',
        id: 0,
        image: '',
        jobTitle: '',
        lastName: '',
        linkedInUrl: null,
        instagramHandle: null,
        memberProfile: '',
        memberType: null,
        middleName: '',
        mobile: null,
        name: '',
        parentCustomerId: null,
        parentCustomerName: '',
        duesPayerId: null,
        parentBilling: createDtoAddress(),
        parentShipping: createDtoAddress(),
        personalAddress: createDtoAddress(),
        personalEmail: null,
        personalMobile: null,
        personalPhone: null,
        phone: null,
        shippingAddress: createDtoAddress(),
        xHandle: '',
        useParentBilling: false,
        useParentShipping: false,
        volunteerWork: [],
        website: null,
        memberSince: null,
        membershipExpires: null,
        incompleteSignup: false,
        membershipStatus: MemberStatus.NonMember,
        totalSeats: 0,
        assignedSeats: 0,
        userId: '',
        autoRenew: false,
        autoPay: false,
        defaultBillablePartyId: null,
        approved: false
    };

    if (dtoCustomer) {
        Object.assign(obj, dtoCustomer);
    }

    return obj;
}