import { Component, Vue, Prop, Inject, Watch } from 'vue-property-decorator';
import { CreditCardTokenData } from '@/common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';

declare let noviNs: INoviNsPaymentGlobals;

@Component({
    name: 'StripeCardInput',
    components: {
        FieldValidationErrors
    }
})
export default class StripeCardInput extends Vue {
    @Prop({ required: false, default: () => true })
    creditCardActive!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Inject('validator')
    $validator: any;
    
    private stripe?: stripe.Stripe;
    private card?: stripe.elements.Element;
    
    createStripePaymentToken(card: CreditCardTokenData) {
        const stripeCard: stripe.TokenOptions = {
            name: card.nameOnCard,
            address_line1: card.billingStreet,
            address_line2: '',
            address_city: card.billingCity,
            address_state: card.billingState,
            address_zip: card.billingZip
        };

        if (noviNs.alternatePaymentCurrency) {
            stripeCard.currency = noviNs.alternatePaymentCurrency;
            stripeCard.address_country = card.billingCountry;
        } else {
            stripeCard.currency = "usd";
            stripeCard.address_country = "USA";
        }

        if (!this.stripe || !this.card)
            return;

        var self = this;

        this.stripe.createToken(this.card, stripeCard).then(function (result: stripe.TokenResponse) {
            self.errors.remove('cardNumber');

            if (result.error) {
                //console.log("Token creation error: " + result.error.message);
                self.errors.add({ field: 'cardNumber', msg: result.error.message || 'There was an unexpected error.' });
                self.emitError(result.error.message || 'There was an unexpected error.');
            } else if (!result.token) {
                //console.log("Token creation error: Token came back blank without error");
                self.errors.add({ field: 'cardNumber', msg: 'There was an error generating the stripe payment token.' });
                self.emitError('There was an error generating the stripe payment token.');
            } else {
                //console.log("Token creation successful: " + result.token.id);
                card.cardToken = result.token.id;
                card.last4Digits = result.token.card?.last4 ?? '';
                self.emitPaymentToken(card);
            }
        });
    }

    emitPaymentToken(card: CreditCardTokenData) {
        this.$emit('on-payment-token-ready', card);
    }

    emitError(errorMsg: string) {
        this.$emit('on-error', errorMsg);
    }

    emitCardChange() {
        this.$emit('on-card-change');
    }

    @Watch('locked', { immediate: true })
    onValueChanged(isLocked: boolean) {
        if (this.card) {
            this.card.update({ disabled: isLocked });
        }
    }

    stripeSetup() {
        if (noviNs.alternatePaymentStripeId) {
            this.stripe = Stripe(noviNs.alternatePaymentStripeId);
        } else {
            this.stripe = Stripe(noviNs.stripeKey);
        }

        var elements = this.stripe.elements();
        this.card = elements.create('card', { hidePostalCode: true });
        this.card.mount('#vue-card-element');
        this.card.on('change', (event: any) => {
            this.errors.remove('cardNumber');            

            if (event.error) {
                this.errors.add({
                    field: 'cardNumber',
                    msg: event.error.message
                });
            }

            this.emitCardChange();
        });
    }

    created() {
        
    }

    mounted() {
        var self = this;
        if (noviNs.stripeKey || noviNs.alternatePaymentStripeId) {
            //Mounted should mean the DOM is ready for this thing, but if Stripe isn't loaded yet we have to wait
			self.stripeSetup();
        }
        else {
            this.emitError("Stripe is choosen as the payment processor but no API key was found. Please contact an admin.");
        } 
    }
}