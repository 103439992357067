import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';
import PhoneInput from '../../components/inputs/phone/PhoneInput.vue';
import { AchTokenData, createAchTokenData, getQboErrorMessage, PaymentProcessorEnum, PaymentProcessorOptions, QboApiErrorResponse } from '@/common/types/AllTypesExports';
import { default as QuickBooksPaymentsCardInputClass } from './QuickBooksPaymentsCardInputVue';
import { qbPaymentsRepo } from '../../common/repositories/QuickbooksPaymentsRepo';
import { AxiosResponse } from 'axios';
import { PropOptions } from 'vue';
import routingNumberImage from '../../assets/routingNumber.svg';
import accountNumberImage from '../../assets/accountNumber.svg';

declare let noviNs: INoviNsPaymentGlobals;

type PhoneMapping = { [key: number]: string };

@Component({
    name: 'AchEntryForm',
    components: {
        PhoneInput,
        FieldValidationErrors,
    }
})
export default class AchEntryFormClass extends Vue {

    @Prop({ required: true } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: false, default: () => true })
    validate!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Prop({ required: false, default: () => false })
    saveAchOption!: boolean;

    @Prop({ required: false, default: () => false })
    forceSaveAch!: boolean;

    achData: AchTokenData = createAchTokenData();

    rawPhone: string = '';
    maskedPhone: string = '';

    @Inject('validator')
    $validator: any;

    @Watch('forceSaveAch', { immediate: true })
    onForceSaveAch(forceSave: boolean) {
        if (this.saveAchOption && forceSave)
            this.achData.saveAch = true;
    }

    maskPhone() {
        let numbers = this.maskedPhone.replace(/\D/g, ''); // Extract numbers
        let masked = '';
        let phoneFormat: PhoneMapping = {
            0: '(',
            3: ') ',
            6: '-'
        };

        // Update masked display value with each character input.
        for (let i = 0; i < numbers.length; i++) {
            masked += (phoneFormat[i] || '') + numbers[i];
        }

        this.maskedPhone = masked;
        this.achData.phone = numbers;

        // Trigger validation after updating rawPhone
        this.$validator.validate('achPhone', this.rawPhone);
    }

    get accountTypesDisplay(): { value: string; label: string }[] {
        return [
            { value: 'PERSONAL_CHECKING', label: 'Consumer Checking' },
            { value: 'PERSONAL_SAVINGS', label: 'Consumer Savings' },
            { value: 'BUSINESS_CHECKING', label: 'Business Checking' },
            { value: 'BUSINESS_SAVINGS', label: 'Business Savings' },
        ];
    }

    get isPaymentProcessorQuickBookPayments() {
        return noviNs.paymentProcessor == PaymentProcessorEnum.quickBooksPayments;
    }

    onAchTokenReady(token: AchTokenData) {
        Object.assign(this.achData, token);
        this.$emit('on-payment-token-ready', token);
    }

    onError(errorMsg: string) {
        this.emitError(errorMsg);
    }

    emitError(errorMsg: string) {
        this.$emit('on-error-toggle', true, errorMsg);
    }

    createPaymentToken(autoPay: boolean, billablePartyId: number) {
        this.achData.autoPay = autoPay;
        this.achData.billablePartyId = billablePartyId;
        this.createAchPaymentToken(this.achData);
    }

    createAchPaymentToken(achEntry: AchTokenData) {
        const achData = {
            bankAccount: {
                name: achEntry.nameOnAccount,
                accountNumber: achEntry.accountNumber,
                routingNumber: achEntry.routingNumber,
                phone: achEntry.phone.replace(/\D/g, ''),
                accountType: achEntry.accountType
            }
        }

        return qbPaymentsRepo.getQuickbooksPaymentToken(achData)
            .then((token: string) => {
                achEntry.achToken = token;
                this.emitPaymentToken(achEntry);
            })
            .catch((axiosResponse: AxiosResponse<QboApiErrorResponse>) => {
                // We are logging axiosResponse object to the console
                console.log(axiosResponse);
                this.emitError(`There was an issue processing your ACH payment. Details: ${getQboErrorMessage(axiosResponse.data)}`);
            });

    }

    emitPaymentToken(achEntry: AchTokenData) {

        // Do not pass sensitive ACH data to novi
        const achTokenData: AchTokenData = {
            achToken: achEntry.achToken,
            storedBankAccountId: achEntry.storedBankAccountId,
            saveAch: achEntry.saveAch,
            autoPay: achEntry.autoPay,
            authorizationChecked: achEntry.authorizationChecked,
            last4Digits: achEntry.accountNumber.substr(-4),
            billablePartyId: achEntry.billablePartyId,
            routingNumber: '',
            accountNumber: '',
            confirmAccountNumber: '',
            accountType: '',
            nameOnAccount: '',
            phone: '',
        };

        this.$emit('on-ach-token-ready', achTokenData);
    }

    mounted() {
        // Initialize Bootstrap popovers
        ($(this.$refs.popoverRoutingNumber as HTMLElement) as any).popover({
            html: true,
            trigger: "hover",
            container: "body",
            content: `Your Routing number is the first set of numbers on the bottom of your checks.
                <img class="routing-number" src="${routingNumberImage}" alt="Routing Number Image"/>`
        });

        ($(this.$refs.popoverAccountNumber as HTMLElement) as any).popover({
            html: true,
            trigger: "hover",
            container: "body",
            content: `Your Account number is the second set of numbers on the bottom of your checks.
                <img class="account-number" src="${accountNumberImage}" alt="Account Number Image"/>`
        });
    }
}